import React, { Component } from 'react';
import logo from '../images/logo.png';
import {
  Link
} from "react-router-dom";
import Button from '@material-ui/core/Button';
import {firebaseAuth, fireStore} from '../constants';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import Grid from '@material-ui/core/Grid';

class Register extends Component {
  constructor(props) {
    super(props);
    this.defaultState = {
      firstName: '',
      lastName: '',
      username: '',
      confirmUsername: '',
      password: '',
      confirmPassword: '',
      disabled: true,
      error: false,
      showConfirmation: false
    };
    this.state = this.defaultState
    this.timestamp = new Date().getTime(); //used for name

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetForm = this.resetForm.bind(this);
  }


  componentWillUnmount() {
    // remove rule when it is not needed
    ValidatorForm.removeValidationRule('isPasswordMatch');
    ValidatorForm.removeValidationRule('isUsernameMatch');
    this.removeListener = null;
    //this.removeListener();
  }
  componentDidMount() {

    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
        if (value !== this.state.password) {
            return false;
        }
        return true;
    });
    ValidatorForm.addValidationRule('isUsernameMatch', (value) => {
        if (value !== this.state.username) {
            return false;
        }
        return true;
    });
    this.removeListener = firebaseAuth().onAuthStateChanged((user) => {
      if (user) {
        //ensure only first time writes
        const usersRef = fireStore().collection("profiles").doc(user.uid);
        usersRef.get()
          .then((docSnapshot) => {
            if (docSnapshot.exists) {
              this.setState({ showConfirmation: true});
            } else {
              let data = { 
                'firstName': this.state.firstName,
                'lastName': this.state.lastName,
                'email': this.state.username

              };
              data.created_at = fireStore.FieldValue.serverTimestamp();

              fireStore().collection("profiles").doc(user.uid).set(data).then((r) => { 
                user.sendEmailVerification(
                  {
                    url: 'https://www.desembarco.io/login?user=' + firebaseAuth().currentUser.email
                  }).then(() => {
                  // Email sent.
                  this.setState({ showConfirmation: true}, () =>{
                    firebaseAuth().signOut().then(() =>{ 
                      });
                    });
                  })
                }).catch(function(error) {

              });
            }
        });

        
      } else {
        
      }
    });
  }
  resetForm() {
    this.setState(this.defaultState, () => {
      this.refs.form.resetValidations();
    });
  }

  handleChange(event) {   
    let newState = {};
    let formName = event.target.name.replace(this.timestamp, '');

    newState[formName] = event.target.value;
    this.setState(newState, () => {
      if(this.refs.form) {
        this.refs.form.isFormValid().then((r) => {
          this.setState({disabled: !r});
        });
      }

    });
  }

  handleSubmit(event) {
    event.preventDefault();
    firebaseAuth().createUserWithEmailAndPassword(this.state.username, this.state.password).then((r) => {
      r.user.updateProfile({
        displayName: this.state.firstName + ' ' +this.state.lastName
      }).then((update) => {
        firebaseAuth().signInWithEmailAndPassword(this.state.username, this.state.password).catch(function(error) {
        });
      });
      

    }).catch((error) => {
      this.setState({error: error.message});
    });
  }


  renderForm() {
    let error = null;
    if(this.state.error) {
      error = (<div className="error">{this.state.error}</div>);
    }

    let cancelbtn = null;
    let buttonCol = 6;
    if(this.defaultState !== this.state) {
      buttonCol = 3;
      cancelbtn = (<Grid item xs={3}><Button onClick={this.resetForm} className="login-btn" variant="contained"  type="button" >
                    Reset
                  </Button></Grid>);
    }

    return (
      <div className="login-form register-form">
        <div className="logo-wrapper">
          <Link to="/"><img src={logo} alt="Welcome"/></Link>
        </div>
        <h2>Register</h2>
        <div className="required-note"><span>*</span> indicates required field.</div>
        {error}
        <ValidatorForm
              ref="form"
              onSubmit={this.handleSubmit}
              onError={errors => console.log(errors)}
          >
          <Grid container spacing={1}>  
              <Grid item xs={6}>
                <div className="form-row">
                  <TextValidator 
                    fullWidth 
                    required
                    name={'firstName'+this.timestamp} 
                    value={this.state.firstName} 
                    onChange={this.handleChange}  
                    label="Enter your first name"
                    validators={['required', 'minStringLength:1']}
                    errorMessages={['This field is required', 'this field is required']}
                    />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="form-row">
            <TextValidator 
              fullWidth 
              required
              name={'lastName'+this.timestamp} 
              value={this.state.lastName} 
              onChange={this.handleChange}  
              label="Enter your last name"
              validators={['required', 'minStringLength:1']}
              errorMessages={['This field is required', 'this field is required']}
              />
          </div>
              </Grid>
            </Grid>
          
          
          <div className="form-row">
            <TextValidator 
              fullWidth 
              required
              autoComplete="off"
              name={'username'+this.timestamp} 
              value={this.state.username} 
              onChange={this.handleChange}  
              label="Enter your email"
              validators={['required', 'isEmail']}
              errorMessages={['This field is required', 'Check your email format']}
              />
          </div>
          <div className="form-row">
            <TextValidator 
              fullWidth 
              required
              autoComplete="off"
              name={'confirmUsername'+this.timestamp} 
              value={this.state.confirmUsername} 
              onChange={this.handleChange}  
              label="Confirm your email"
              validators={['required', 'isUsernameMatch']}
              errorMessages={['This field is required', 'Email does not match']}
              />
          </div>
          <div className="form-row">
            <TextValidator 
              fullWidth 
              required
              type="password"
              name={'password'+this.timestamp} 
              value={this.state.password} 
              onChange={this.handleChange}  
              label="Enter your password"
              validators={['required', 'minStringLength:6']}
              errorMessages={['This field is required', 'Password must be longer than 6 characters']}
              />
          </div>
          <div className="form-row">
            <TextValidator 
              fullWidth 
              required
              name={'confirmPassword'+this.timestamp} 
              type="password"
              value={this.state.confirmPassword} 
              onChange={this.handleChange}  
              label="Confirm your password" 
              validators={['required', 'isPasswordMatch']}
              errorMessages={['This field is required', 'Password does not match']}
            />
          </div>
          <div className="form-btns">
            <Grid container spacing={3}>  
              <Grid item xs={3}>
              </Grid>

                {cancelbtn}
              <Grid item xs={buttonCol}>
                <Button className="login-btn" variant="contained" color="primary" type="submit" disabled={this.state.disabled}>
                  Register
                </Button>
              </Grid>
            </Grid>
            
            
          </div>
        </ValidatorForm>
        <div className="register-wrapper">
          <Link to="/login">Already have an account?</Link>
        </div>
      </div>
    );
  }
  renderConfirmation() {
    return (<div className="login-form register-form">
        <div className="logo-wrapper">
          <Link to="/"><img src={logo} alt="Welcome"/></Link>
        </div>
        <p>Thank you for registering, a confirmation email has been sent to '{this.state.username}'. </p><p>Please check for it and follow the 
        instructions.  If you don't see the email, please check your other inboxes in your email account.</p>
      <div className="register-wrapper">
          <Link to="/login">Login</Link>
        </div>
    </div>);
  }

  render() {
    if(this.state.showConfirmation) {
      return (<div className="page-wrapper">
       
        {this.renderConfirmation()}</div>);
    } else {
      return (<div className="page-wrapper">
       
        {this.renderForm()}</div>);  
    }
    
  }
}

export default Register;
