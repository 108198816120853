import React, { Component } from "react";
import { fireStore } from "../constants";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import Header from "../components/header";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Backdrop from "@material-ui/core/Backdrop";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from "react-material-ui-form-validator";
import omit from "lodash/omit";
import isEqual from "lodash/isEqual";
import map from "lodash/map";
import pick from "lodash/pick";
import filter from "lodash/filter";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

const styles = theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
});

class FlightsEdit extends Component {
  constructor(props) {
    super(props);
    this.collectionName = "flights";
    this.state = {
      action: "",
      busy: true,
      disabled: true,
      showSuccess: false,
      error: false,
      openDelete: false,
      wines: [],
      tables: [],
      flight: {
        name: "",
        lc_name: "",
        group_name: "",
        wines: [],
        table_id: "",
        table: {}
      }
    };
    this.defaultState = {
      action: "",
      busy: true,
      disabled: true,
      showSuccess: false,
      error: false,
      openDelete: false,
      wines: [],
      tables: [],
      flight: {
        name: "",
        lc_name: "",
        group_name: "",
        wines: [],
        table_id: "",
        table: {}
      }
    };
    this.timestamp = new Date().getTime(); //used for name
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.checkForUnique = this.checkForUnique.bind(this);
    this.openDeleteDialog = this.openDeleteDialog.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.handleCloseDelete = this.handleCloseDelete.bind(this);
    this.gotoRoute = this.gotoRoute.bind(this);
    this.addWine = this.addWine.bind(this);
    this.removeWine = this.removeWine.bind(this);
    this.handleWineselectChange = this.handleWineselectChange.bind(this);

    this.collectionsRef = fireStore().collection(this.collectionName);
    this.winesRef = fireStore().collection("wines");
    this.tablesRef = fireStore().collection("tables");
  }
  componentWillUnmount() {}
  componentDidMount() {
    let action = "";
    if (this.props.match.params.id === "add") {
      action = "Add";
    } else {
      action = "Edit";
    }
    if (action === "Edit") {
      this.collectionsRef
        .doc(this.props.match.params.id)
        .get()
        .then(docSnapshot => {
          if (docSnapshot.exists) {
            let data = docSnapshot.data();
            data.id = docSnapshot.id;

            let newObject = Object.assign({}, this.defaultState.flight, data);
            newObject.wines = map(data.wines, (item, key) => {
              return Object.assign({}, item);
            });
            this.defaultState.flight = newObject;

            let existing = Object.assign({}, this.state.flight, data);

            Promise.all([
              this.winesRef.orderBy("lc_name", "asc").get(),
              this.tablesRef.orderBy("lc_name", "asc").get()
            ]).then(selectOptions => {
              let winesData = [];
              selectOptions[0].forEach(doc => {
                let data = doc.data();
                data.id = doc.id;
                winesData.push(data);
              });

              let tablesData = [];
              selectOptions[1].forEach(doc => {
                let data = doc.data();
                data.id = doc.id;
                tablesData.push(data);
              });

              this.setState(
                {
                  flight: existing,
                  action: action,
                  busy: false,
                  wines: winesData,
                  tables: tablesData
                },
                () => {}
              );
            });
          } else {
            Promise.all([
              this.winesRef.orderBy("lc_name", "asc").get(),
              this.tablesRef.orderBy("lc_name", "asc").get()
            ]).then(selectOptions => {
              let winesData = [];
              selectOptions[0].forEach(doc => {
                let data = doc.data();
                data.id = doc.id;
                winesData.push(data);
              });

              let tablesData = [];
              selectOptions[1].forEach(doc => {
                let data = doc.data();
                data.id = doc.id;
                tablesData.push(data);
              });

              this.setState(
                {
                  action: "Add",
                  busy: false,
                  wines: winesData,
                  tables: tablesData
                },
                () => {}
              );
            });
          }
        })
        .catch(err => {
          console.log("Error getting documents", err);
        });
    } else {
      Promise.all([
        this.winesRef.orderBy("lc_name", "asc").get(),
        this.tablesRef.orderBy("lc_name", "asc").get()
      ]).then(selectOptions => {
        let winesData = [];
        selectOptions[0].forEach(doc => {
          let data = doc.data();
          data.id = doc.id;
          winesData.push(data);
        });

        let tablesData = [];
        selectOptions[1].forEach(doc => {
          let data = doc.data();
          data.id = doc.id;
          tablesData.push(data);
        });

        this.setState(
          {
            action: "Add",
            busy: false,
            wines: winesData,
            tables: tablesData
          },
          () => {}
        );
      });
      this.setState({ action: action, busy: false });
    }
  }
  handleClose() {
    this.setState({ showSuccess: false }, () => {
      this.props.history.push("/flights");
    });
  }
  handleCloseDelete() {
    this.setState({ openDelete: false }, () => {});
  }

  handleChange(event) {
    let newState = {};
    let formName = event.target.name.replace(this.timestamp, "");
    if (formName === "table_id") {
      newState[formName] = event.target.value;
      let theTable = filter(this.state.tables, (item, key) => {
        return item.id === this.state.tables.table_id;
      });
      if (theTable.length) {
        newState.table = pick(theTable[0], ["name", "lc_name", "id"]);
      }
    } else {
      newState[formName] = event.target.value;
    }

    let currentState = Object.assign({}, this.state.flight, newState);
    this.setState({ flight: currentState }, () => {
      if (this.refs.form) {
        let omitKeys = [""];
        if (
          isEqual(
            omit(this.state.flight, omitKeys),
            omit(this.defaultState.flight, omitKeys)
          )
        ) {
          this.setState({ disabled: true });
        } else {
          this.refs.form.isFormValid().then(r => {
            console.log("r", !r);
            this.setState({ disabled: !r });
          });
        }
      }
    });
  }

  checkForUnique() {
    let itemRef = fireStore().collection(this.collectionName);
    if (
      this.state.flight.name.toLowerCase() !==
      this.defaultState.flight.name.toLowerCase()
    ) {
      return itemRef
        .where("lc_name", "==", this.state.flight.name.toLowerCase())
        .get()
        .then(snapshot => {
          if (snapshot.empty) {
            return true;
          }
        })
        .catch(err => {
          console.log("Error getting documents", err);
        });
    } else {
      return Promise.resolve(true);
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    this.checkForUnique().then(r => {
      if (r) {
        let data = {
          name: this.state.flight.name,
          lc_name: this.state.flight.name.toLowerCase(),
          wines: this.state.flight.wines,
          table_id: this.state.flight.table_id,
          table: this.state.flight.table,
          group_name: this.state.flight.group_name,
          updated_at: fireStore.FieldValue.serverTimestamp(),
          last_updated_by: this.props.user.uid
        };
        if (this.state.action === "Add") {
          data.created_at = fireStore.FieldValue.serverTimestamp();
          data.created_user = this.props.user.uid;
        } else {
          //          data.created_at = this.state.flight.created_at;
          //        data.created_user = this.state.flight.created_user;
        }
        //clean out empty wines
        data.wines = filter(data.wines, (item, key) => {
          return item.wine_id !== "";
        });
        this.setState({ busy: true }, () => {
          let theRef;
          if (this.state.action === "Add") {
            theRef = fireStore()
              .collection(this.collectionName)
              .add(data);
          } else {
            theRef = fireStore()
              .collection(this.collectionName)
              .doc(this.state.flight.id)
              .set(data); //edit
          }
          theRef
            .then(r => {
              this.setState({
                busy: false,
                disabled: true,
                error: false,
                showSuccess: true
              });
            })
            .catch(error => {
              // An error happened.
              this.setState({
                error: error.message,
                busy: false,
                disabled: true,
                showSuccess: false
              });
            });
        });
        this.refs.form.resetValidations();
      } else {
        this.setState({
          error:
            "A flight with that name already exists, please use a unique flight name",
          busy: false,
          disabled: true,
          showSuccess: false
        });
        this.refs.form.resetValidations();
      }
    });
  }

  openDeleteDialog() {
    this.setState({ openDelete: true });
  }
  deleteItem() {
    return fireStore()
      .collection("flights")
      .doc(this.state.flight.id)
      .delete()
      .then(r => {
        this.props.history.push("/flights");
      });
  }
  gotoRoute(route) {
    this.props.history.push(route);
  }

  addWine() {
    let flight = this.state.flight;
    let wines = [...flight.wines];
    wines.push({
      wine_id: "",
      order: this.state.flight.wines.length + 1
    });
    flight.wines = wines;
    this.setState({ flight: flight }, () => {
      let omitKeys = [];
      if (
        isEqual(
          omit(this.state.flight.wines, omitKeys),
          omit(this.defaultState.flight.wines, omitKeys)
        )
      ) {
        this.setState({ disabled: true });
      } else {
        this.refs.form.isFormValid().then(r => {
          this.setState({ disabled: false });
        });
      }
    });
  }
  removeWine(index) {
    let flight = this.state.flight;
    let wines = map(flight.wines, (item, key) => {
      return Object.assign({}, item);
    });
    if (wines.length > 0) {
      wines.splice(index, 1);
      flight.wines = wines;

      this.setState({ flight: flight }, () => {
        let omitKeys = [];
        if (
          isEqual(
            omit(this.state.flight.wines, omitKeys),
            omit(this.defaultState.flight.wines, omitKeys)
          )
        ) {
          this.setState({ disabled: true });
        } else {
          this.refs.form.isFormValid().then(r => {
            this.setState({ disabled: false });
          });
        }
      });
    }
  }
  handleWineselectChange(event, key, uid) {
    let flight = this.state.flight;
    let wines = [...flight.wines];

    if (uid === "president") {
      wines = map(wines, (item, key) => {
        delete item.president;
        return item;
      });
      wines[key][uid] = event.target.value;
    } else {
      wines[key][uid] = event.target.value;
    }
    if (uid === "wine_id") {
      let theWine = filter(this.state.wines, (item, key) => {
        return item.id === event.target.value;
      });

      if (theWine.length) {
        wines[key]["wine"] = pick(theWine[0], [
          "name",
          "winery_name",
          "id",
          "lc_name",
          "wine_code"
        ]);
      }
    }

    flight.wines = wines;

    this.setState({ flight: flight }, () => {
      let omitKeys = [];
      if (
        isEqual(
          omit(this.state.flight.wines, omitKeys),
          omit(this.defaultState.flight.wines, omitKeys)
        )
      ) {
        this.setState({ disabled: true });
      } else {
        this.refs.form.isFormValid().then(r => {
          this.setState({ disabled: false });
        });
      }
    });
  }
  render() {
    const { classes } = this.props;
    let error = null;
    if (this.state.error) {
      error = <div className="error">{this.state.error}</div>;
    }
    let deletebtn = null;
    if (this.state.action === "Edit") {
      deletebtn = (
        <Button
          onClick={this.openDeleteDialog}
          className="delete-btn"
          variant="contained"
          color="primary"
          type="button"
        >
          Delete Flight
        </Button>
      );
    }
    let usedWines = map(this.state.flight.wines, (i, k) => {
      return i.wine_id;
    });
    let winesOptions = this.state.wines.map((item, key) => {
      if (usedWines.includes(item.id)) {
        return (
          <MenuItem dense={true} disabled key={key} value={item.id}>
            {item.name} | {item.winery_name}, {item.winery_city} |{" "}
            {item.wine_code}
          </MenuItem>
        );
      } else {
        return (
          <MenuItem dense={true} key={key} value={item.id}>
            {item.name} | {item.winery_name}, {item.winery_city} |{" "}
            {item.wine_code}
          </MenuItem>
        );
      }
    });

    let tableOptions = this.state.tables.map((item, key) => {
      return (
        <MenuItem dense={true} key={key} value={item.id}>
          {item.name}
        </MenuItem>
      );
    });

    var tableItems = this.state.flight.wines.map((item, key) => {
      return (
        <li key={key} className="tasting-item">
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={1}>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  id={"Judge_Order_" + key}
                  value={item.order}
                  onChange={event => {
                    this.handleWineselectChange(event, key, "order");
                  }}
                  type="number"
                />
              </FormControl>
            </Grid>
            <Grid item xs={10}>
              <FormControl fullWidth>
                <Select
                  variant="outlined"
                  labelId={"Wine_" + key}
                  id={"Wine_Select_" + key}
                  value={item.wine_id}
                  displayEmpty
                  onChange={event => {
                    this.handleWineselectChange(event, key, "wine_id");
                  }}
                >
                  <MenuItem dense={true} value="">
                    Select Wine
                  </MenuItem>
                  {winesOptions}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1}>
              <Fab
                color="primary"
                size="small"
                aria-label="add"
                onClick={() => {
                  this.removeWine(key);
                }}
                className="remove-tasting-btn"
              >
                -
              </Fab>
            </Grid>
          </Grid>
        </li>
      );
    });
    let tasting_content;
    if (this.state.flight.wines.length) {
      tasting_content = (
        <div>
          <Grid container className="grid-header" spacing={3}>
            <Grid item xs={1}>
              Order
            </Grid>
            <Grid item xs={10}>
              Wine
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
          <ul className="wines-list">{tableItems}</ul>
        </div>
      );
    } else {
      tasting_content = <p>There are no wines added.</p>;
    }
    let saveText = this.state.action;
    if (this.state.action === "Edit") {
      saveText = "Save";
    }
    return (
      <div className="logged-in-with-header">
        <div className="login-form dashboard">
          <div className="admin tables">
            <Header
              user={this.props.user}
              profile={this.props.profile}
              developer={this.props.developer}
              administrator={this.props.administrator}
            />

            <div className="page-content">
              <Link className="back-link" to="/flights">
                <i className="fas fa-chevron-left"></i> Back
              </Link>
              <div className="actions">{deletebtn}</div>
              <h1>{this.state.action} Flights</h1>
              <div className="form">
                {error}
                <ValidatorForm
                  ref="form"
                  onSubmit={this.handleSubmit}
                  onError={errors => console.log(errors)}
                >
                  <div className="form-row">
                    <TextValidator
                      fullWidth
                      name={"name" + this.timestamp}
                      value={this.state.flight.name}
                      onChange={this.handleChange}
                      label="Enter a Flight Name"
                      validators={["required", "minStringLength:1"]}
                      errorMessages={[
                        "This field is required",
                        "This field is required"
                      ]}
                    />
                  </div>

                  <div className="form-row">
                    <TextValidator
                      fullWidth
                      name={"group_name" + this.timestamp}
                      value={this.state.flight.group_name}
                      onChange={this.handleChange}
                      label="Enter a Group Name"
                      validators={["required", "minStringLength:1"]}
                      errorMessages={[
                        "This field is required",
                        "This field is required"
                      ]}
                    />
                  </div>

                  <div className="form-row">
                    <SelectValidator
                      fullWidth
                      name={"table_id" + this.timestamp}
                      value={this.state.flight.table_id}
                      label="Select a Table"
                      onChange={this.handleChange}
                      validators={["required", "minStringLength:1"]}
                      errorMessages={[
                        "This field is required",
                        "This field is required"
                      ]}
                    >
                      <MenuItem dense={true} value="">
                        Select Table
                      </MenuItem>
                      {tableOptions}
                    </SelectValidator>
                  </div>

                  <div className="wines">
                    <h2>Wines</h2>
                    {tasting_content}
                    <Button
                      className="add-tasting-btn"
                      onClick={this.addWine}
                      variant="contained"
                      type="button"
                    >
                      + Add
                    </Button>
                  </div>

                  <div className="form-btns">
                    <Grid container spacing={3}>
                      <Grid item xs={2}></Grid>

                      <Grid item xs={6}></Grid>
                      <Grid item xs={2}>
                        <Button
                          className="cancel-btn"
                          onClick={() => {
                            this.gotoRoute("/flights");
                          }}
                          variant="contained"
                          type="button"
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          className="login-btn"
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={this.state.disabled}
                        >
                          {saveText} Flight
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </ValidatorForm>
              </div>
            </div>
            <Backdrop className={classes.backdrop} open={this.state.busy}>
              <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog
              onClose={this.handleClose}
              disableBackdropClick
              aria-labelledby="simple-dialog-title"
              open={this.state.showSuccess}
            >
              <DialogContent>
                Success, your Flight <strong>'{this.state.flight.name}'</strong>{" "}
                has been updated.
              </DialogContent>
              <DialogActions className="logout-actions">
                <Button autoFocus onClick={this.handleClose}>
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              onClose={this.handleCloseDelete}
              aria-labelledby="simple-dialog-title"
              open={this.state.openDelete}
            >
              <DialogTitle>Delete</DialogTitle>
              <DialogContent>
                Are you sure you want to delete{" "}
                <strong>'{this.state.flight.name}'</strong>. This cannot be
                undone.
              </DialogContent>
              <DialogActions className="logout-actions">
                <Button autoFocus onClick={this.handleCloseDelete}>
                  Cancel
                </Button>
                <Button autoFocus onClick={this.deleteItem}>
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(FlightsEdit);