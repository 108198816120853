import React, { Component } from "react";
import { fireStore, styles, applicationTitle} from "../constants";
import Header from "../components/header";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";

const getUuid = require("uuid-by-string");

class Settings extends Component {
  constructor(props) {
    super(props);

    this.applicationTitle = applicationTitle;

    this.state = {
      busy: true,
      settings: {
        openForRegistration: "closed",
        openForLogin: "closed"
      }
    };
    this.collectionRef = fireStore().collection("settings");
    this.getSettings = this.getSettings.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateSettings = this.updateSettings.bind(this);
    this.documentId = getUuid(this.applicationTitle, 5);
  }
  componentWillUnmount() {}

  componentDidMount() {
    if(this.props.settings) {
      this.setState({ busy: false, settings: this.props.settings });
    } else {
      this.setState({ busy: false });
    
    }
    
  }
  updateSettings() {
    this.setState({ busy: true }, () => {
      this.collectionRef
        .doc(this.documentId)
        .set(this.state.settings, { merge: true })
        .then(() => {
          this.setState({ busy: false });
        });
    });
  }
  handleChange(event) {
    console.log("handleChange");

    let newState = {};
    let formName = event.target.name;
    newState[formName] = event.target.value;
    let currentState = Object.assign({}, this.state.settings, newState);
    this.setState({ settings: currentState }, () => {});
  }
  getSettings() {
    return this.collectionRef.doc(this.documentId).get();
  }
  render() {
    const { classes } = this.props;
    return (
      <div className="logged-in-with-header">
        <div className="login-form dashboard">
          <div className="admin tables">
            <Header
              user={this.props.user}
              profile={this.props.profile}
              developer={this.props.developer}
              administrator={this.props.administrator}
            />
            <div className="page-content">
              <h1>Manage Application Settings</h1>
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      Registration
                    </FormLabel>
                    <RadioGroup
                      aria-label="Registration"
                      name="openForRegistration"
                      value={this.state.settings.openForRegistration}
                      onChange={this.handleChange}
                    >
                      <FormControlLabel
                        value="open"
                        control={<Radio />}
                        label="Open Registration"
                      />
                      <FormControlLabel
                        value="closed"
                        control={<Radio />}
                        label="Closed Registration"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={5}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      Application Status 
                    </FormLabel>
                    <RadioGroup
                      aria-label="Registration"
                      name="openForLogin"
                      value={this.state.settings.openForLogin}
                      onChange={this.handleChange}
                    >
                      <FormControlLabel
                        value="open"
                        control={<Radio />}
                        label="Open"
                      />
                      <FormControlLabel
                        value="closed"
                        control={<Radio />}
                        label="Closed"
                      />
                      <FormControlLabel
                        value="results"
                        control={<Radio />}
                        label="Results Only"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>

              <div className="form-btns">
                <Grid container spacing={3}>
                  <Grid item xs={2}></Grid>

                  <Grid item xs={6}></Grid>

                  <Grid item xs={4}>
                    <Button
                      className="login-btn"
                      variant="contained"
                      color="primary"
                      type="button"
                      onClick={this.updateSettings}
                    >
                      Update App Settings
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </div>
            <Backdrop className={classes.backdrop} open={this.state.busy}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(Settings);