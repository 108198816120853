import React, { Component } from 'react';
import {firebaseAuth} from '../constants';
import Header from '../components/header';

class MyTastings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user:false
    }

    firebaseAuth().onAuthStateChanged((user) => {
      if (user) {
        console.log('user', user);
        // User logged in already or has just logged in.
         this.setState({
           user: user
         });
      } else {
        // User not logged in or has just logged out.
        this.setState({
           user: false
         });
      }
    });

  }
  componentWillUnmount() {
    
  }
  componentDidMount() {

  }

  render() {
    return (
    	<div className="my-account">
        <div  className="header">
          <Header user={this.props.user} profile={this.props.profile} developer={this.props.developer} administrator={this.props.administrator}/>
        </div>
        <h1>My Tastings</h1>
      </div>
    );
  }
}

export default MyTastings;