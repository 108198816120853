import React, { Component } from "react";
import { fireStore } from "../constants";
import Fab from "@material-ui/core/Fab";
import Header from "../components/header";
import AddIcon from "@material-ui/icons/Add";
import Backdrop from "@material-ui/core/Backdrop";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
const styles = theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
});
class Tables extends Component {
  constructor(props) {
    super(props);
    this.collectionName = "tables";
    this.state = {
      user: false,
      tables: [],
      busy: true
    };

    this.gotoRoute = this.gotoRoute.bind(this);
  }
  componentWillUnmount() {}
  componentDidMount() {
    let tablesRef = fireStore().collection(this.collectionName);
    tablesRef
      .orderBy("lc_name", "asc")
      .get()
      .then(snapshot => {
        let tableData = [];
        snapshot.forEach(doc => {
          let data = doc.data();
          data.id = doc.id;
          tableData.push(data);
        });
        this.setState({ tables: tableData, busy: false });
      })
      .catch(err => {
        console.log("Error getting documents", err);
      });
  }

  gotoRoute(route) {
    this.props.history.push(route);
  }

  render() {
    const { classes } = this.props;
    var tableItems = this.state.tables.map(item => {
      var judges = item.judges.map(i => {
        let activeClass = "";
        if (i.president === "true") {
          activeClass = " active";
        }
       
        i.profiles = Object.assign({}, { firstName: '', lastName: ''}, i.profiles);
        return (
          <span key={i.profile_id} className={"judge" + activeClass}>
            {i.profiles.firstName} {i.profiles.lastName}
          </span>
        );
      });
      return (
        <li key={item.id}>
          <Link to={"/tables/" + item.id}>{item.name}</Link>
          <div>{judges}</div>
        </li>
      );
    });
    let content;
    if (this.state.tables.length) {
      content = (
        <div>
          <h3>{this.state.tables.length} Total Tables</h3>
          <ul>{tableItems}</ul>
        </div>
      );
    } else {
      content = <p>There are no tables added.</p>;
    }

    return (
      <div className="logged-in-with-header">
        <div className="login-form dashboard">
          <div className="admin tables">
            <Header
              user={this.props.user}
              profile={this.props.profile}
              developer={this.props.developer}
              administrator={this.props.administrator}
            />
            <div className="page-content">
              <div className="actions">
                <Fab
                  onClick={() => {
                    this.gotoRoute("/tables/add");
                  }}
                  size="small"
                  color="primary"
                  aria-label="add"
                >
                  <AddIcon />
                </Fab>
              </div>
              <h1>Manage Tables</h1>
              <div className="table-content">{content}</div>
            </div>
            <Backdrop className={classes.backdrop} open={this.state.busy}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Tables);