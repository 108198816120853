import React, { Component } from "react";
import { firebaseAuth, fireStore, theme, applicationTitle } from "./constants";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import "./App.scss";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Login from "./screens/login";
import Register from "./screens/register";
import Dashboard from "./screens/dashboard";
import Confirmemail from "./screens/confirm-email";
import ForgotPassword from "./screens/forgot-password";
import MyAccount from "./screens/my-account";

import Tables from "./screens/tables";
import TablesEdit from "./screens/tables-edit";
import Flights from "./screens/flights";
import FlightsEdit from "./screens/flights-edit";
import Wines from "./screens/wines";
import WinesEdit from "./screens/wines-edit";
import Questions from "./screens/questions";
import QuestionsEdit from "./screens/questions-edit";

import TastingEdit from "./screens/tasting-edit";

import MyTasting from "./screens/my-tastings";

import Users from "./screens/users";
import NotFound from "./screens/404";
import RegistrationNotOpen from "./screens/registration-not-open";
import LoginNotOpen from "./screens/login-not-open";

import Settings from "./screens/settings";
import Reports from "./screens/reports";

//Route controllers/componnents
import PublicRoute from "./components/public-route";

import getUuid from "uuid-by-string";

function PrivateRoute({
  component: Component,
  authed,
  user,
  profile,
  developer,
  administrator,
  settings,
  updateProfile,
  ...rest
}) {
  
  if ({ ...rest }.path !== "/my-account" && (settings.openForLogin === "closed" && !developer) && user.emailVerified) {
    return  <Route
      {...rest}
      render={props => {
        return (
          <LoginNotOpen
            {...props}
            user={user}
            profile={profile}
            developer={developer}
            administrator={administrator}
            settings={settings}
          />
        );
      }}
    />
  }

  if (user && !user.emailVerified) {
    return (
      <Route
        {...rest}
        render={props => {
          return (
            <Confirmemail
              {...props}
              user={user}
              profile={profile}
              developer={developer}
              administrator={administrator}
              settings={settings}
              updateProfile={updateProfile}
            />
          );
        }}
      />
    );
  } else {
    return (
      <Route
        {...rest}
        render={props =>
          authed === true ? (
            <Component
              {...props}
              user={user}
              profile={profile}
              developer={developer}
              administrator={administrator}
              settings={settings}
              updateProfile={updateProfile}
            />
          ) : (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        }
      />
    );
  }
}

function DeveloperRoute({
  component: Component,
  authed,
  user,
  profile,
  developer,
  administrator,
  settings,
  updateProfile,
  ...rest
}) {
  if (user && !user.emailVerified) {
    return (
      <Route
        {...rest}
        render={props => {
          return (
            <Confirmemail
              {...props}
              user={user}
              profile={profile}
              developer={developer}
              administrator={administrator}
              settings={settings}
              updateProfile={updateProfile}
            />
          );
        }}
      />
    );
  } else {
    if (developer) {
      return (
        <Route
          {...rest}
          render={props =>
            authed === true ? (
              <Component
                {...props}
                user={user}
                profile={profile}
                developer={developer}
                administrator={administrator}
                settings={settings}
                updateProfile={updateProfile}
              />
            ) : (
              <Redirect
                to={{ pathname: "/login", state: { from: props.location } }}
              />
            )
          }
        />
      );
    } else {
      return (
        <NotFound
          user={user}
          profile={profile}
          developer={developer}
          administrator={administrator}
          settings={settings}
          updateProfile={updateProfile}
        />
      );
    }
  }
}



class App extends Component {
  state = {
    authed: false,
    loading: true
  };
  constructor(props) {
    super(props);
    this.getData = this.getData.bind(this);
  }
  componentDidMount() {
    this.removeListener = firebaseAuth().onAuthStateChanged(user => {
      if (user) {
        this.user = user;
        this.getData(user);
      } else {
        this.getSettings().then(r => {
          this.setState({
            authed: false,
            loading: false,
            user: false,
            profile: false,
            settings: r
          });
        });
      }
    });
  }
  getSettings() {
    const settingsRef = fireStore()
      .collection("settings")
      .doc(getUuid(applicationTitle, 5));
    return settingsRef.get().then(snapshot => {
      if (snapshot.exists) {
        return snapshot.data();
      } else {
        return false;
      }
    });
  }
  getData(user) {
    let propsState = {
      authed: true,
      loading: false,
      user: user,
      developer: false,
      administrator: false
    };
    const usersRef = fireStore()
      .collection("profiles")
      .doc(user.uid);
    const developRoleRef = fireStore()
      .collection("developers")
      .doc(user.uid);
    const adminRoleRef = fireStore()
      .collection("administrators")
      .doc(user.uid);

    Promise.all([
      usersRef.get(),
      developRoleRef.get(),
      adminRoleRef.get(),
      this.getSettings()
    ]).then(snapshot => {
      if (snapshot[0].exists) {
        propsState.profile = snapshot[0].data();
      }
      if (snapshot[1].exists) {
        propsState.developer = true;
      }
      if (snapshot[2].exists) {
        propsState.administrator = true;
      }

      if (snapshot[3]) {
        propsState.settings = snapshot[3];
      }

      this.setState(propsState, () => {});
    });
  }
  componentWillUnmount() {
    this.removeListener();
  }
  render() {
    if (!this.state.loading) {
      return (
        <Router>
          <ThemeProvider theme={theme}>
            <div className="App">
              <Switch>
                <PublicRoute
                  path="/login"
                  authed={this.state.authed}
                  user={this.state.user}
                  settings={this.state.settings}
                  component={Login}
                />
                <PublicRoute
                  path="/register"
                  authed={this.state.authed}
                  user={this.state.user}
                  settings={this.state.settings}
                  component={Register}
                />
                <PublicRoute
                  path="/forgot-password"
                  authed={this.state.authed}
                  user={this.state.user}
                  settings={this.state.settings}
                  component={ForgotPassword}
                />

                <PrivateRoute
                  authed={this.state.authed}
                  path="/confirm-email"
                  user={this.state.user}
                  profile={this.state.profile}
                  developer={this.state.developer}
                  administrator={this.state.administrator}
                  settings={this.state.settings}
                  updateProfile={this.getData}
                  component={Confirmemail}
                />

                <PrivateRoute
                  exact
                  authed={this.state.authed}
                  path="/my-tastings"
                  user={this.state.user}
                  profile={this.state.profile}
                  developer={this.state.developer}
                  administrator={this.state.administrator}
                  settings={this.state.settings}
                  updateProfile={this.getData}
                  component={MyTasting}
                />

                <PrivateRoute
                  exact
                  authed={this.state.authed}
                  path="/my-account"
                  user={this.state.user}
                  profile={this.state.profile}
                  developer={this.state.developer}
                  administrator={this.state.administrator}
                  settings={this.state.settings}
                  updateProfile={this.getData}
                  component={MyAccount}
                />

                <PrivateRoute
                  exact
                  authed={this.state.authed}
                  path="/tasting/:table_id/:flight_id/:wine_code"
                  user={this.state.user}
                  profile={this.state.profile}
                  developer={this.state.developer}
                  administrator={this.state.administrator}
                  settings={this.state.settings}
                  updateProfile={this.getData}
                  component={TastingEdit}
                />

                <DeveloperRoute
                  exact
                  authed={this.state.authed}
                  path="/settings"
                  user={this.state.user}
                  profile={this.state.profile}
                  developer={this.state.developer}
                  administrator={this.state.administrator}
                  settings={this.state.settings}
                  updateProfile={this.getData}
                  component={Settings}
                />

                <DeveloperRoute
                  exact
                  authed={this.state.authed}
                  path="/reports"
                  user={this.state.user}
                  profile={this.state.profile}
                  developer={this.state.developer}
                  administrator={this.state.administrator}
                  settings={this.state.settings}
                  updateProfile={this.getData}
                  component={Reports}
                />

                <DeveloperRoute
                  exact
                  authed={this.state.authed}
                  path="/tables"
                  user={this.state.user}
                  profile={this.state.profile}
                  developer={this.state.developer}
                  administrator={this.state.administrator}
                  settings={this.state.settings}
                  updateProfile={this.getData}
                  component={Tables}
                />

                <DeveloperRoute
                  exact
                  authed={this.state.authed}
                  path="/tables/:id"
                  user={this.state.user}
                  profile={this.state.profile}
                  developer={this.state.developer}
                  administrator={this.state.administrator}
                  settings={this.state.settings}
                  updateProfile={this.getData}
                  component={TablesEdit}
                />

                <DeveloperRoute
                  exact
                  authed={this.state.authed}
                  path="/flights"
                  user={this.state.user}
                  profile={this.state.profile}
                  developer={this.state.developer}
                  administrator={this.state.administrator}
                  settings={this.state.settings}
                  updateProfile={this.getData}
                  component={Flights}
                />

                <DeveloperRoute
                  exact
                  authed={this.state.authed}
                  path="/flights/:id"
                  user={this.state.user}
                  profile={this.state.profile}
                  developer={this.state.developer}
                  administrator={this.state.administrator}
                  settings={this.state.settings}
                  updateProfile={this.getData}
                  component={FlightsEdit}
                />

                <DeveloperRoute
                  exact
                  authed={this.state.authed}
                  path="/wines"
                  user={this.state.user}
                  profile={this.state.profile}
                  developer={this.state.developer}
                  administrator={this.state.administrator}
                  updateProfile={this.getData}
                  component={Wines}
                />

                <DeveloperRoute
                  exact
                  authed={this.state.authed}
                  path="/wines/:id"
                  user={this.state.user}
                  profile={this.state.profile}
                  developer={this.state.developer}
                  administrator={this.state.administrator}
                  settings={this.state.settings}
                  updateProfile={this.getData}
                  component={WinesEdit}
                />

                <DeveloperRoute
                  authed={this.state.authed}
                  path="/users"
                  user={this.state.user}
                  profile={this.state.profile}
                  developer={this.state.developer}
                  administrator={this.state.administrator}
                  settings={this.state.settings}
                  updateProfile={this.getData}
                  component={Users}
                />

                <DeveloperRoute
                  authed={this.state.authed}
                  path="/questions/:id"
                  user={this.state.user}
                  profile={this.state.profile}
                  developer={this.state.developer}
                  administrator={this.state.administrator}
                  settings={this.state.settings}
                  updateProfile={this.getData}
                  component={QuestionsEdit}
                />

                <DeveloperRoute
                  authed={this.state.authed}
                  path="/questions"
                  user={this.state.user}
                  profile={this.state.profile}
                  developer={this.state.developer}
                  administrator={this.state.administrator}
                  settings={this.state.settings}
                  updateProfile={this.getData}
                  component={Questions}
                />

                <PrivateRoute
                  exact
                  authed={this.state.authed}
                  path="/"
                  user={this.state.user}
                  profile={this.state.profile}
                  developer={this.state.developer}
                  administrator={this.state.administrator}
                  settings={this.state.settings}
                  updateProfile={this.getData}
                  component={Dashboard}
                />

                <PublicRoute
                  exact
                  path="/404"
                  authed={this.state.authed}
                  user={this.state.user}
                  profile={this.state.profile}
                  developer={this.state.developer}
                  administrator={this.state.administrator}
                  settings={this.state.settings}
                  updateProfile={this.getData}
                  component={NotFound}
                />

                <Route
                  render={() => (
                    <NotFound
                      authed={this.state.authed}
                      user={this.state.user}
                      profile={this.state.profile}
                      developer={this.state.developer}
                      administrator={this.state.administrator}
                      settings={this.state.settings}
                      updateProfile={this.getData}
                    />
                  )}
                />
              </Switch>
            </div>
          </ThemeProvider>
        </Router>
      );
    } else {
      return (
        <Backdrop open={this.state.loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      );
    }
  }
}

export default App;