import firebase from 'firebase'
import { createMuiTheme } from '@material-ui/core/styles';

export const firebaseConfig = {
  apiKey: "AIzaSyDzKHwSz6JPWfxM--58nk7q-xu1IRD0nn4",
  authDomain: "barolowinetasting.firebaseapp.com",
  databaseURL: "https://barolowinetasting.firebaseio.com",
  projectId: "barolowinetasting",
  storageBucket: "barolowinetasting.appspot.com",
  messagingSenderId: "1098169637727",
  appId: "1:1098169637727:web:c6849706531d64002f74ea",
  measurementId: "G-28Z3BHTEYL"
};

export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const ref = firebase.database().ref()
export const fireStore = firebase.firestore;
export const fireBase = firebase.database;
export const firebaseAuth = firebase.auth

export const theme = createMuiTheme({
  palette: {
    primary: { 500: '#f26a23' },
    secondary: { main: '#990000' }
  },
});

export const styles = theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
});

export const applicationTitle = "Barolow-Barbaresco World Opening - 2020";

