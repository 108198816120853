import React, { Component } from "react";
import { firebaseAuth } from "../constants";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Popover from "@material-ui/core/Popover";
import { withRouter } from "react-router";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

const styles = theme => ({
  root: {
    background: "#6f172d",
    position: "fixed",
    width: "100%",
    top: "0px",
    zIndex: "100",
    left: "0px"
  }
});

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      logoutDialog: false
    };

    this.openMeMenu = this.openMeMenu.bind(this);
    this.handleClosePopover = this.handleClosePopover.bind(this);
    this.logout = this.logout.bind(this);
    this.gotoRoute = this.gotoRoute.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.doLogout = this.doLogout.bind(this);
  }
  componentWillUnmount() {}
  componentDidMount() {}
  handleClose() {
    this.setState({ logoutDialog: false });
  }
  doLogout() {
    firebaseAuth()
      .signOut()
      .then(() => {
        this.setState({ logoutDialog: true, open: false }, () => {
          window.location.replace('/login');
        });
      });
  }
  logout() {
    this.setState({ logoutDialog: true, open: false });
  }
  handleClosePopover() {
    this.setState({ open: false });
  }
  openMeMenu(e) {
    if (true) {
      this.setState({ anchorEl: e.currentTarget, open: !this.state.open });
    }
  }
  gotoRoute(route) {
    this.props.history.push(route);
  }
  render() {
    const { classes } = this.props;
    if (!this.props.profile) {
      return null;
    } else {
      let developerMenuItems = null;
      if (this.props.developer) {
        developerMenuItems = (
          <div>
            <Divider />
            <ListItem
              button
              onClick={() => {
                this.gotoRoute("/tables");
              }}
            >
              <ListItemText primary="Manage Tables" />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                this.gotoRoute("/flights");
              }}
            >
              <ListItemText primary="Manage Flights" />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                this.gotoRoute("/wines");
              }}
            >
              <ListItemText primary="Manage Wines" />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                this.gotoRoute("/users");
              }}
            >
              <ListItemText primary="Manage Users" />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                this.gotoRoute("/questions");
              }}
            >
              <ListItemText primary="Manage Questions" />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                this.gotoRoute("/settings");
              }}
            >
              <ListItemText primary="Manage App Settings" />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                this.gotoRoute("/reports");
              }}
            >
              <ListItemText primary="Reports" />
            </ListItem>
          </div>
        );
      }
      return (
        <div className={classes.root}>
          <Grid container>
            <Grid item xs={6}></Grid>
            <Grid className="me-col" item xs={6}>
              <div ref={this.meMenu} className="me" onClick={this.openMeMenu}>
                {this.props.profile.firstName} {this.props.profile.lastName}
              </div>
              <Popover
                anchorEl={this.state.anchorEl}
                open={this.state.open}
                onClose={this.handleClosePopover}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
              >
                <List
                  dense
                  className="me-dropdown"
                  component="nav"
                  aria-label="main mailbox folders"
                >
                  <ListItem
                    button
                    onClick={() => {
                      this.gotoRoute("/my-account");
                    }}
                  >
                    <ListItemIcon>
                      <AccountBoxIcon />
                    </ListItemIcon>
                    <ListItemText primary="My Account" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      this.gotoRoute("/");
                    }}
                  >
                    <ListItemIcon>
                      <i className="fas fa-wine-glass-alt nav-icon"></i>
                    </ListItemIcon>
                    <ListItemText primary="My Tastings" />
                  </ListItem>
                  {developerMenuItems}
                  <Divider />
                  <ListItem button onClick={this.logout}>
                    <ListItemIcon>
                      <ExitToAppIcon />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                  </ListItem>
                </List>
              </Popover>
            </Grid>
          </Grid>
          <Dialog
            onClose={this.handleClose}
            aria-labelledby="simple-dialog-title"
            open={this.state.logoutDialog}
          >
            <DialogTitle id="simple-dialog-title">
              Are you sure you want to logout?
            </DialogTitle>
            <DialogActions className="logout-actions">
              <Button autoFocus onClick={this.handleClose}>
                Cancel
              </Button>
              <Button onClick={this.doLogout} className="btn btn-primary">
                Logout
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    }
  }
}

export default withRouter(withStyles(styles)(Header));