import React, { Component } from 'react';
import logo from '../images/logo.png';
import {
  Link
} from "react-router-dom";
import Button from '@material-ui/core/Button';
import {firebaseAuth} from '../constants';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import Grid from '@material-ui/core/Grid';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.defaultState = {
      username: '',
      showConfirmation: false
    };
    this.state = this.defaultState
    this.timestamp = new Date().getTime(); //used for name

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetForm = this.resetForm.bind(this);
  }


  componentWillUnmount() {
    // remove rule when it is not needed
    //this.removeListener();
  }
  componentDidMount() {

  }
  resetForm() {
    this.setState(this.defaultState, () => {
      this.refs.form.resetValidations();
    });
  }

  handleChange(event) {   
    let newState = {};
    let formName = event.target.name.replace(this.timestamp, '');

    newState[formName] = event.target.value;
    this.setState(newState, () => {
      if(this.refs.form) {
        this.refs.form.isFormValid().then((r) => {
          this.setState({disabled: !r});
        });
      }

    });
  }

  handleSubmit(event) {
    event.preventDefault();
    firebaseAuth().sendPasswordResetEmail(this.state.username).then(() => {
      // Email sent.
       this.setState({showConfirmation: true});
    }).catch((error) => {
      // An error happened.
       this.setState({error: error.message});
    });
  }


  renderForm() {
    let error = null;
    if(this.state.error) {
      error = (<div className="error">{this.state.error}</div>);
    }

    return (
      <div className="login-form register-form">
        <div className="logo-wrapper">
          <Link to="/"><img src={logo} alt="Welcome"/></Link>
        </div>
        <div className="loginBox">
          <h2>Forgot Password</h2>
          {error}

          <ValidatorForm
                ref="form"
                onSubmit={this.handleSubmit}
                onError={errors => console.log(errors)}
            >
            <div className="form-row">
              <TextValidator 
                fullWidth 

                autoComplete="off"
                name={'username'+this.timestamp} 
                value={this.state.username} 
                onChange={this.handleChange}  
                label="Enter your email"
                validators={['required', 'isEmail']}
                errorMessages={['This field is required', 'Check your email format']}
                />
            </div>
            <div className="form-btns">
              <Grid container spacing={3}>  
                <Grid item xs={12}>
                  <Button className="login-btn" variant="contained" color="primary" type="submit" disabled={this.state.disabled}>
                    Reset your password
                  </Button>
                </Grid>
              </Grid>
              
              
            </div>
          </ValidatorForm>
        </div>
        <div className="register-wrapper">
          <Link to="/login">Back to login?</Link>
        </div>
      </div>
    );
  }
  renderConfirmation() {
    return (<div className="login-form register-form">
      <div className="logo-wrapper"><img src={logo} alt="Welcome"/></div>
      <p>A password reset has been sent to : {this.state.username}, please check your email inbox.</p>
      <div className="register-wrapper">
          <Link to="/login">Back to login</Link>
        </div>
    </div>);
  }

  render() {
    if(this.state.showConfirmation) {
      return (<div className="page-wrapper">
       
        {this.renderConfirmation()}</div>);
    } else {
      return (<div className="page-wrapper">
        {this.renderForm()}</div>);  
    }
    
  }
}

export default ForgotPassword;
