import React, { Component } from 'react';
import {fireStore} from '../constants';
import Grid from '@material-ui/core/Grid';
import Header from '../components/header';
import logo from '../images/logo.png';
import each from 'lodash/each';
import map from 'lodash/map';
import keys from 'lodash/keys';
import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';
import filter from 'lodash/filter';

import Backdrop from '@material-ui/core/Backdrop';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
const styles = theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
});
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tables: [],
      busy: true,
    };
    this.tablesRef = fireStore().collection('tables');
    this.flightsRef = fireStore().collection('flights');
    this.submissionsRef = fireStore().collection('submissions');
    this.gotoRoute = this.gotoRoute.bind(this);
  }
  componentWillUnmount() {
    
  }

  gotoRoute(route) {
    this.props.history.push(route);
  }

  componentDidMount() {
    this.getSubmissions().then((submissions) => {
      this.getTables().then((r) => {
        this.getTheFlights(r).then((result) => {
          let normalizedData = {};
          each(result, 
            (d,ke) => { 
              let data = {};
              data.name = d.name;
              data.table_lc_name = d.lc_name;
              data.table_id = d.id;
              data.wines = [];
              each(d.flights, (df,kf) => {
                
                each(df.wines, (dw,kw) => {
                  dw.table_name = d.name;
                  dw.table_lc_name = d.lc_name;
                  dw.table_id = d.id;
                  dw.flight_name = df.name;
                  dw.flight_lc_name = df.lc_name;
                  dw.flight_id = df.id;
                  dw.group_name = df.group_name;

                  let test = filter(submissions, (s, sk) => { 
                    return (s.table_id === dw.table_id && 
                      s.flight_id === dw.flight_id && 
                      s.wine_code === dw.wine.wine_code &&
                      s.last_updated_by === this.props.user.uid)
                  });
                  if(test.length) {
                    dw.inprogress = test.length;
                    if(test[0].confirmed === true) {
                      dw.confirmed = true;
                    }
                    if(test[0].rejected === true) {
                      dw.rejected = true;
                    }
                  }
                  

                  data.wines.push(dw);



                });
              });
              data.wines = orderBy(data.wines, [(item) => {
                let splitname = item.flight_lc_name.split(' ');
                let theOrder = parseInt(splitname[splitname.length-1]);
                if(isNaN(theOrder)) {
                  return item.flight_lc_name  
                } else {
                  return theOrder;
                }
                
              }, (item) => {
                return item.order;
              }]);

              data.wines = groupBy(data.wines, 'flight_name');

              normalizedData[d.id] = data;

            }
          );


          

          this.setState({ 'tables': normalizedData, busy: false}, () => {
   
          });
        });

      });
    });
  }

  getSubmissions() {
    return this.submissionsRef.get().then((snapshot) => {
      let mytables = [];
      if(snapshot.size) {

        snapshot.forEach((doc) => {
          let data = doc.data();
          data.id = doc.id;
          mytables.push(data);
        });
      } else {

      }

      return mytables;
    });
  }

  getTheFlights(tables) {

    let theTableIds = map(tables, (i,k)=>{
      return i.id;
    });
    //get the flights
    let theFlightsPromises = [];
    theTableIds.forEach((id) => {
      theFlightsPromises.push(this.flightsRef.where('table_id', '==', id).get());
    });
    return Promise.all([theFlightsPromises]).then((tablesSnapshots) => {
      let promises = [];

      tablesSnapshots.forEach((theProm) => {
        theProm.forEach((theItem) => {
          promises.push(theItem);      
        });   
      });
      return Promise.all(promises).then((r) => {
        let data = {};
        r.forEach((ref, k) => { 
          data[theTableIds[k]] = tables[k];
          data[theTableIds[k]].flights = [];
          ref.forEach((flightref) => {
            let td = flightref.data();
            td.id = flightref.id;
            data[theTableIds[k]].flights.push(td);
          });

        });
        return data;
      });
    });
  }

  getTables() {
    let mytables = [];
    return this.tablesRef.where('judges_uids', 'array-contains', this.props.user.uid).orderBy('lc_name', 'asc').get().then((snapshot) => {
      if(snapshot.size) {
        snapshot.forEach((doc) => {
          let data = doc.data();
          data.id = doc.id;
          mytables.push(data);
        });
      } else {

      }

      return mytables;
    });
  }

  render() {
    const { classes } = this.props;

    let tables = map(this.state.tables, (item,key) => {
      
      let flightcolumnnumber = 0;
      let flights = map(item.wines, (flight, flightname) => {
        
        let wines = map(flight, (w, wk) => {
         
          let className = "";
          let icon = (null);
          if(w.rejected) {
            className = "rejected";
            icon = (<div className="status-icon"><i className="far fa-clock"></i></div>);
              
          } else {
            if(w.inprogress) {
              className = "in-progress";
              icon = (<div className="status-icon"><i className="far fa-clock"></i></div>);
              if(w.confirmed) {
                className = "confirmed";
                icon = (<div className="status-icon"><i className="fas fa-check"></i></div>);
              }
            }
          }
          return (<Grid key={w.wine_id+w.table_id+w.flight_id+flightcolumnnumber} className={'tastings ' + className} item sm={12} >
            <div className="flight-tasting" onClick={()=>{ this.gotoRoute('/tasting/'+w.table_id+'/'+w.flight_id+'/'+w.wine.wine_code)}}>
              <div className="group-name">{w.group_name} #{w.order}</div>
              {icon}
            </div>
            </Grid>);
        });
        flightcolumnnumber = flightcolumnnumber + 1;
        return (<Grid item key={flightcolumnnumber} sm={3}>
           <div className="flight-group-header">Flight {flightcolumnnumber}</div>
            <Grid alignItems="center" justify="center" alignContent="center" container spacing={1}>
              {wines}
            </Grid>
           
          </Grid>);
 
      });


      return (<div className="tasting-container" key={key}><h2 className="table-name">{item.name}</h2>
          <Grid alignItems="flex-start" justify="center" alignContent="center" container className="tastings-wrapper"  spacing={1}>
            {flights}
          </Grid>
        </div>);
    });


    let content = (<p>You are not apart of any tables yet. Please check back later</p>);
    if(keys(this.state.tables).length) {
      content = (<div className="table-group">{tables}</div>);
    }

    return (
    <div className="logged-in-with-header">
      <div className="login-form dashboard">
        <Header user={this.props.user} profile={this.props.profile} developer={this.props.developer} administrator={this.props.administrator}/>
        <Grid alignItems="flex-start" justify="center" alignContent="center" container className="tastings-wrapper"  spacing={1}>
          <Grid item sm={9}>
            <div className="tasting-header-left">
              <h1>{this.props.profile.firstName} {this.props.profile.lastName}</h1>
            </div>
          </Grid>
          <Grid item sm={3}>
             <div className="tasting-header-right"><img src={logo} alt="Welcome"/></div>
          </Grid>
        </Grid>
       
        
        <div className="page-wrapper">
         <div className="tables">
            {content}
          </div>
        </div>
         <Backdrop   
            className={classes.backdrop}      
            open={this.state.busy}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
      </div>
    </div>
    );
  }
}

export default withStyles(styles)(Dashboard);