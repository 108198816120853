import React, { Component } from "react";
import { fireStore } from "../constants";
import Fab from "@material-ui/core/Fab";
import Header from "../components/header";
import AddIcon from "@material-ui/icons/Add";
import Backdrop from "@material-ui/core/Backdrop";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
const styles = theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
});
class Questions extends Component {
  constructor(props) {
    super(props);
    this.collectionName = "questions";
    this.state = {
      user: false,
      questions: [],
      busy: true
    };

    this.gotoRoute = this.gotoRoute.bind(this);
  }
  componentWillUnmount() {}
  componentDidMount() {
    let questionsRef = fireStore().collection(this.collectionName);
    questionsRef
      .orderBy("order_int", "asc")
      .get()
      .then(snapshot => {
        let userData = [];
        snapshot.forEach(doc => {
          let data = doc.data();
          data.id = doc.id;
          userData.push(data);
        });
        this.setState({ questions: userData, busy: false });
      })
      .catch(err => {
        console.log("Error getting documents", err);
      });
  }

  gotoRoute(route) {
    this.props.history.push(route);
  }

  render() {
    const { classes } = this.props;
    var profileItems = this.state.questions.map(function(item) {
      return (
        <li key={item.id}>
          <Link to={"/questions/" + item.id}>
            <strong>{item.group}: </strong>
            {item.name}{" "}
          </Link>
        </li>
      );
    });
    let content;
    if (this.state.questions.length) {
      content = (
        <div>
          <h3>{this.state.questions.length} Total Questions</h3>
          <ul>{profileItems}</ul>
        </div>
      );
    } else {
      content = <p>There are no questions added.</p>;
    }

    return (
      <div className="logged-in-with-header">
        <div className="login-form dashboard">
          <div className="admin tables">
            <Header
              user={this.props.user}
              profile={this.props.profile}
              developer={this.props.developer}
              administrator={this.props.administrator}
            />

            <div className="page-content">
              <div className="actions">
                <Fab
                  onClick={() => {
                    this.gotoRoute("/questions/add");
                  }}
                  size="small"
                  color="primary"
                  aria-label="add"
                >
                  <AddIcon />
                </Fab>
              </div>
              <h1>Manage Questions</h1>
              <div className="table-content">{content}</div>
            </div>
            <Backdrop className={classes.backdrop} open={this.state.busy}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Questions);