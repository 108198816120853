import React, { Component, useState, useEffect } from "react";
import { fireStore, styles, applicationTitle} from "../constants";
import { AverageByGroup, AverageByCommune, MedalsByPercantage} from "../components/function-components";
import Header from "../components/header";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import { CSVLink, CSVDownload } from "react-csv";
import getUuid from "uuid-by-string";

import omit from "lodash/omit";
import isEqual from "lodash/isEqual";
import map from "lodash/map";
import pick from "lodash/pick";
import each from "lodash/each";
import filter from "lodash/filter";
import reduce from "lodash/reduce";
import moment from 'moment';
import uniqBy from "lodash/uniqBy";
import sumBy from "lodash/sumBy";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';




class Reports extends Component {
  constructor(props) {
    super(props);

    this.applicationTitle = applicationTitle;

    this.state = {
      busy: true,
      settings: {
        openForRegistration: "closed",
        openForLogin: "closed"
      },
      dataRaw: false,
      csvData : [],
      questionKeys: [],
      facets: {
        group: false
      }
    };
    this.questionKeys = [];
    this.collectionRef = fireStore().collection("submissions");
    this.getSubmissions = this.getSubmissions.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateSettings = this.updateSettings.bind(this);
    this.runStatCalculations = this.runStatCalculations.bind(this);
    this.getAverageScoreByGroup = this.getAverageScoreByGroup.bind(this);
    this.documentId = getUuid(this.applicationTitle, 5);
  
    this.getAllWines = this.getAllWines.bind(this);
    this.getAllFlights = this.getAllFlights.bind(this);
    this.getAllTables = this.getAllTables.bind(this);
  }
  componentWillUnmount() {}

  componentDidMount() {
    Promise.all([this.getAllWines(),this.getAllFlights(),this.getAllTables()]).then((rollupData) => { 
      this.setState({ winesData: rollupData[0], flightsData: rollupData[1], tablesData: rollupData[2]}, () => {
        this.getSubmissions().then((r) => {
          if(r) {
             this.setState({ busy: false, dataRaw: r, csvData: this.getCSVDateFromRaw(r), questionKeys: this.questionKeys }, ()=>{
               this.runStatCalculations();
             });
          } else {
            this.setState({ busy: false, dataRaw: false, csvData: [], questionKeys: [] });
          }
        });
      });
    });
    
    
  }
  getAllTables() {
    return fireStore().collection("tables").get().then(snapshot => {
        let winesData = {};
        snapshot.forEach(doc => {
          let data = doc.data();
          data.id = doc.id;
          winesData[doc.id] = data;
        });
        return winesData;
       
      })
      .catch((err) => {
        return false;
      });
  }
  getAllFlights() {
    return fireStore().collection("flights").get().then(snapshot => {
        let winesData = {};
        snapshot.forEach(doc => {
          let data = doc.data();
          data.id = doc.id;
          winesData[doc.id] = data;
        });
        return winesData;
       
      })
      .catch((err) => {
        return false;
      });
  }
  getAllWines() {
    return fireStore().collection("wines").get().then(snapshot => {
        let winesData = {};
        snapshot.forEach(doc => {
          let data = doc.data();
          data.id = doc.id;
          winesData[data.wine_code] = data;
        });
        return winesData;
       
      })
      .catch((err) => {
        return false;
      });
  }
  runStatCalculations() {
    this.getAverageScoreByGroup();
    this.getAverageScoreByCity();
    this.getPercentGroups();
  }

  getMedalCounts(min, max, data) {
    let filtered = filter(data, (item, key) => {
      return (item.average >= min) && (item.average <=max)
    });
    return filtered.length;
  }

  getPercentGroups() {
    /*- % of wines that won a double gold medal (96-100 average score)
- % of wines that won a gold medal (95-90  avg score)
- % of wines that won a silver medal ( 86 - 89 avg. Score)
- % of wines that won a bronce medal  (82 to 85)*/    
    //let groups = uniqBy(map(this.state.csvData, (item) => { return item.winery_group; }), 'winery_group');
    let cleanData = filter(this.state.csvData, (item) => {
       return !item.rejected;
    });
    let groups  = [ ...new Set(map(cleanData, (item) => {  return item.winery_name; })) ];
    let averages = {}
    each(groups, (item, key) => {
      averages[item] = {};
      let filtered = filter(cleanData, (i,k) => {
        return i.winery_name === item;
      });
      let filteredSum = sumBy(filtered, function(o) {   
        if(isNaN(o.total)) {
          return 0;
        } else {
          return o.total;
        }
        
      });
      averages[item]['sum'] = filteredSum;
      averages[item]['count'] = filtered.length;
      averages[item]['average'] = filteredSum/filtered.length;
    });
    //console.log('groups', groups);
    let facets = this.state.facets;
    facets.wines = averages;
    
    facets.medals = {
      double_gold: this.getMedalCounts(96,100,averages),
      gold:  this.getMedalCounts(90,95,averages),
      silver:  this.getMedalCounts(86,89,averages),
      bronze:  this.getMedalCounts(82,85,averages),
      below:  this.getMedalCounts(0,81,averages)
    };

    let total_medals = reduce(facets.medals, (memo, item, key) => {
      
      memo = memo + item;
      return memo;
    }, 0);
    
    facets.medals_percents = reduce(facets.medals, (memo, item, key) => {
      if(item > 0) {
        memo[key] = (item/total_medals) * 100;
      }
      return memo;
    }, {});

    facets.medals_percents_pie_data = map(facets.medals_percents, (item, key) => {
      return { label: key, value: item } ;
    });

    this.setState({ facets: facets }, () => { console.log('this', this.state);});

  }

  getAverageScoreByCity() {
    let cleanData = filter(this.state.csvData, (item) => {
       return !item.rejected;
    });
    //let groups = uniqBy(map(this.state.csvData, (item) => { return item.winery_group; }), 'winery_group');
    let groups  = [ ...new Set(map(cleanData, (item) => {  return item.winery_city; })) ];
    let averages = {}
    each(groups, (item, key) => {
      averages[item] = {};
      let filtered = filter(cleanData, (i,k) => {
        return i.winery_city === item;
      });
      let filteredSum = sumBy(filtered, function(o) {   
        if(isNaN(o.total)) {
          return 0;
        } else {
          return o.total;
        }
        
      });
      averages[item]['sum'] = filteredSum;
      averages[item]['count'] = filtered.length;
    });
    //console.log('groups', groups);
    let facets = this.state.facets;
    facets.commune = averages;
    this.setState({ facets: facets }, () => { });
  }

  getAverageScoreByGroup() {
    let cleanData = filter(this.state.csvData, (item) => {
       return !item.rejected;
    });
    //let groups = uniqBy(map(this.state.csvData, (item) => { return item.winery_group; }), 'winery_group');
    let groups  = [ ...new Set(map(cleanData, (item) => { return item.winery_group; })) ];
    let averages = {}
    each(groups, (item, key) => {
      averages[item] = {};
      let filtered = filter(cleanData, (i,k) => {
        return i.winery_group === item;
      });
      let filteredSum = sumBy(filtered, function(o) {   
        if(isNaN(o.total)) {
          return 0;
        } else {
          return o.total;
        }
        
      });
      averages[item]['sum'] = filteredSum;
      averages[item]['count'] = filtered.length;
    });
    //console.log('groups', groups);
    let facets = this.state.facets;
    facets.group = averages;
    this.setState({ facets: facets }, () => { });

  }
  getCSVDateFromRaw(data) {
    this.questionKeys = [];
    let formatted = map(data, (item, key) =>{
      let theUserProfile = {};
      if(item.user_profile) {
        theUserProfile = item.user_profile;
      }
      let total = 0;
      let questions = reduce(item.questions, (memo, d, k) => {
        let data = reduce(d, (memod, dd, kk) => {
          let qKey  = 'Q:'+k+'_'+dd.name+'_value';
          if(this.questionKeys.indexOf(qKey) === -1) {
            this.questionKeys.push(qKey);
          }
          memod[qKey] = dd.value;
          if(!isNaN(parseInt(dd.value))) {
            total = total + parseInt(dd.value);
          }
          
          return memod
        },{});
        return Object.assign({}, memo, data);
      },{});

      let returnData = {
        confirmed: (item.confirmed) ? item.confirmed: false ,
        rejected: (item.rejected) ? item.rejected: false ,
        flight_name: this.state.flightsData[item.flight_id].name,
        table_name: this.state.tablesData[item.table_id].name,
        user_firstName: (theUserProfile.firstName) ? theUserProfile.firstName: '',
        user_lastName: (theUserProfile.lastName) ? theUserProfile.lastName: '',
        user_email: (theUserProfile.email) ? theUserProfile.email: '',
        wine_code: item.wine_code,
        wine: this.state.winesData[item.wine_code].name,
        submission_id: item.id,
        tasting_notes: item.tasting_notes,
        updated_at: moment(item.updated_at.toDate()).format('MMMM Do YYYY, h:mm:ss a'),
        winery_group: item.winery_group,
        winery_city: item.winery_city,
        winery_name: item.winery_name,
        total: total
      };
      return Object.assign({}, returnData, questions);
    });
    return formatted;
  }



  updateSettings() {
    this.setState({ busy: true }, () => {
      this.collectionRef
        .doc(this.documentId)
        .set(this.state.settings, { merge: true })
        .then(() => {
          this.setState({ busy: false });
        });
    });
  }
  handleChange(event) {

    let newState = {};
    let formName = event.target.name;
    newState[formName] = event.target.value;
    let currentState = Object.assign({}, this.state.settings, newState);
    this.setState({ settings: currentState }, () => {});
  }
  getSubmissions() {

    return this.collectionRef.orderBy("updated_at", "desc").get().then(snapshot => {
        let submissionData = [];
        snapshot.forEach(doc => {
          let data = doc.data();
          data.id = doc.id;
          submissionData.push(data);
        });
        return submissionData;
       
      })
      .catch((err) => {
        return false;
      });
  }
  outputConfirmed(v) {
    if(v.rejected) {
      return 'Rejected';
    } else {
      if(v.confirmed) {
        return 'Confirmed';
      } else{
        return 'In Progress';
      }
    }
  }
  formatHeaderQKey(v) {
 
    return v.replace(/_/gi, ' ').replace(/value/gi, '').replace(/Q:/gi, '')
  }

  render() {
    const { classes } = this.props;

    let tables = null;
    tables = (
        <TableContainer className="submissions" component={Paper}>
          <Table size="small" stickyHeader aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Confirmed</TableCell>
                <TableCell align="right">Flight</TableCell>
                <TableCell align="right">Table</TableCell>
                <TableCell align="right">First Name</TableCell>
                <TableCell align="right">Last Name</TableCell>
                <TableCell align="right">Email</TableCell>
                {this.state.questionKeys.map(qkey => (
                  <TableCell key={qkey} align="right">{this.formatHeaderQKey(qkey)}</TableCell>
                ))}
                <TableCell align="right">Total Score</TableCell>
                <TableCell align="right">Winery Group</TableCell>
                <TableCell align="right">Winery City</TableCell>
                <TableCell align="right">Winery Name</TableCell>
                <TableCell align="right">Wine Code</TableCell>
                <TableCell align="right">Wine Name</TableCell>
                <TableCell align="right">Tasting Notes</TableCell>
                
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.csvData.map(row => (
                <TableRow key={row.submission_id}>
                  <TableCell component="th" scope="row">
                    {this.outputConfirmed(row)}
                  </TableCell>
                  <TableCell align="right">{row.flight_name}</TableCell>
                  <TableCell align="right">{row.table_name}</TableCell>
                  <TableCell align="right">{row.user_firstName}</TableCell>
                  <TableCell align="right">{row.user_lastName}</TableCell>
                  <TableCell align="right">{row.user_email}</TableCell>
                  {this.state.questionKeys.map(qkey => (
                    <TableCell key={qkey+row.submission_id} align="right">{row[qkey]}</TableCell>
                  ))}
                  <TableCell align="right">{row.total}</TableCell>
                  <TableCell align="right">{row.winery_group}</TableCell>
                  <TableCell align="right">{row.winery_city}</TableCell>
                  <TableCell align="right">{row.winery_name}</TableCell>
                  <TableCell align="right">{row.wine_code}</TableCell>
                  <TableCell align="right">{row.wine}</TableCell>
                  <TableCell align="right">{row.tasting_notes}</TableCell>
                  
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );

   
    




    return (
      <div className="reports logged-in-with-header">
        
        <div className="login-form dashboard">
          <div className="admin tables">
            <Header
              user={this.props.user}
              profile={this.props.profile}
              developer={this.props.developer}
              administrator={this.props.administrator}
            />
            <div className="page-content">
              <h1>Reports</h1>

              <Grid container spacing={3}>
                
                <Grid item xs={12} md={6}>
                  <AverageByGroup facets={this.state.facets}></AverageByGroup>
                  <AverageByCommune facets={this.state.facets}></AverageByCommune> 
                </Grid>

                <Grid item xs={12} md={6}>
                  <MedalsByPercantage facets={this.state.facets}></MedalsByPercantage>
                </Grid>
              </Grid>
              
              <div className="report-section">
                <div className="report-section-inner block">
                  <h2>{this.state.csvData.length} Submissions</h2>
                  {tables}
                  <div className="form-btns">
                    <Grid container spacing={3}>
                      <Grid item xs={2}></Grid>

                      <Grid item xs={6}></Grid>

                      <Grid item xs={4}>
                        <CSVLink filename={"results.csv"}
      className="btn btn-primary" data={this.state.csvData}>Download</CSVLink>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
            <Backdrop className={classes.backdrop} open={this.state.busy}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(Reports);