import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import RegistrationNotOpen from "../screens/registration-not-open";
import Login from "../screens/login";
import Register from "../screens/register";



export default function PublicRoute({
  component: Component,
  authed,
  user,
  settings,
  ...rest
}) {

  //check for open registration
  if ({ ...rest }.path === "/register") {
    if (settings.openForRegistration === "closed") {
      return (
        <Route
          {...rest}
          render={props => {
            return <RegistrationNotOpen {...props} settings={settings} />;
          }}
        />
      );
    }
  }

  if ({ ...rest }.path === "/register" && authed) {
    if (user.emailVerified) {
      return <Redirect to={{ pathname: "/login" }} />;
    } else {
      return (
        <Route path="/register" component={Register} settings={settings} />
      );
    }
  } else {
    if ({ ...rest }.path === "/404") {
      return (
        <Route
          {...rest}
          render={props => {
            return <Component {...props} />;
          }}
        />
      );
    } else {
      return (
        <Route
          {...rest}
          render={props =>
            authed === false ? (
              <Component {...props} settings={settings} />
            ) : (
              <Redirect to="/" />
            )
          }
        />
      );
    }
  }
}