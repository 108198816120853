import React, { Component } from "react";
import logo from "../images/logo.png";
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import { firebaseAuth } from "../constants";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
//import queryString from "query-string";

const ColorButton = withStyles(theme => ({
  root: {
    color: '#fff',
    backgroundColor: '#77c9b2',
    '&:hover': {
      backgroundColor: '#77c9b2',
    },
  },
}))(Button);

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      disabled: true,
      error: false
    };
    this.timestamp = new Date().getTime(); //used for name

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentWillUnmount() {}
  componentDidMount() {
   // const values = queryString.parse(this.props.location.search);
  }

  handleChange(event) {
    let newState = {};
    let formName = event.target.name.replace(this.timestamp, "");

    newState[formName] = event.target.value;
    this.setState(newState, () => {
      if (this.refs.form) {
        this.refs.form.isFormValid().then(r => {
          this.setState({ disabled: !r });
        });
      }
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    firebaseAuth()
      .signInWithEmailAndPassword(this.state.username, this.state.password)
      .then(() => {
        window.location.replace('/');
      })
      .catch(error => {
        // Handle Errors here.
        this.setState({ error: error.message });
        // ...
      });
  }

  render() {
    let error = null;
    if (this.state.error) {
      error = <div className="error">{this.state.error}</div>;
    }
    let registerBtn = null;
    if(this.props.settings.openForRegistration === 'open') {
      registerBtn = (<Link className="btn btn-primary blue-btn" to="/register">
              Get Started
            </Link>);
    }
    return (
      <div className="page-wrapper">
        <div className="login-form login-page">
          <div className="logo-wrapper">
            <img src={logo} alt="Welcome" />
          </div>
          <div className="welcome-wrapper">
            <h1 className="welcome">
              Welcome to the <br />
              Barolo Judges Platform
            </h1>
            <h2 className="welcome-subheader">
              Create your account to participate on the judge panel
            </h2>
          </div>
          <div className="register-wrapper">
            {registerBtn}
          </div>
          <div className="loginBox">
            <h3 className="loginhdr">IF YOU ALREADY HAVE AN ACCOUNT</h3>
            {error}
            <ValidatorForm
              ref="form"
              onSubmit={this.handleSubmit}
              onError={errors => console.log(errors)}
            >
              <div className="form-row">
                <TextValidator
                  fullWidth
                  autoComplete="off"
                  name={"username" + this.timestamp}
                  value={this.state.username}
                  onChange={this.handleChange}
                  label="Enter your email"
                  validators={["required", "isEmail"]}
                  errorMessages={[
                    "this field is required",
                    "email is not valid"
                  ]}
                />
              </div>
              <div className="form-row">
                <TextValidator
                  fullWidth
                  name={"password" + this.timestamp}
                  type="password"
                  value={this.state.password}
                  onChange={this.handleChange}
                  label="Enter your password"
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </div>
              <div className="form-btns">
                <ColorButton
                  className="login-btn"
                  variant="contained"
                  color="default"
                  type="submit"
                  disabled={this.state.disabled}
                >
                  LOGIN
                </ColorButton>
              </div>
            </ValidatorForm>
          </div>
          <div className="register-wrapper">
            <Link className="forgot-password-link" to="/forgot-password">
              Forgot your password?
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;