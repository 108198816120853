import React, { Component } from "react";
import { fireStore } from "../constants";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import Header from "../components/header";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Backdrop from "@material-ui/core/Backdrop";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import omit from "lodash/omit";
import isEqual from "lodash/isEqual";
import map from "lodash/map";
import pick from "lodash/pick";
import filter from "lodash/filter";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";

const styles = theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
});

class TablesEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: false,
      profile: false,
      action: "",
      busy: true,
      disabled: true,
      showSuccess: false,
      error: false,
      openDelete: false,
      profiles: [],
      table: {
        name: "",
        lc_name: "",
        judges: [],
        judges_uids: []
      }
    };
    this.defaultState = {
      user: false,
      profile: false,
      action: "",
      busy: true,
      disabled: true,
      showSuccess: false,
      error: false,
      openDelete: false,
      profiles: [],
      table: {
        name: "",
        lc_name: "",
        judges: [],
        judges_uids: []
      }
    };
    this.collectionName = "tables";

    this.timestamp = new Date().getTime(); //used for name
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.checkForUnique = this.checkForUnique.bind(this);
    this.openDeleteDialog = this.openDeleteDialog.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.gotoRoute = this.gotoRoute.bind(this);
    this.addJudge = this.addJudge.bind(this);
    this.removeJudge = this.removeJudge.bind(this);
    this.handleWineselectChange = this.handleWineselectChange.bind(this);

    this.collectionsRef = fireStore().collection(this.collectionName);
    this.profilesRef = fireStore().collection("profiles");
  }
  componentWillUnmount() {}
  componentDidMount() {
    let action = "";
    if (this.props.match.params.id === "add") {
      action = "Add";
    } else {
      action = "Edit";
    }
    if (action === "Edit") {
      this.collectionsRef
        .doc(this.props.match.params.id)
        .get()
        .then(docSnapshot => {
          if (docSnapshot.exists) {
            let data = docSnapshot.data();
            data.id = docSnapshot.id;

            let newObject = Object.assign({}, this.defaultState.table, data);
            newObject.judges = map(data.judges, (item, key) => {
              return Object.assign({}, item);
            });
            this.defaultState.table = newObject;

            let existing = Object.assign({}, this.state.table, data);
            Promise.all([
              this.profilesRef.orderBy("lastName", "asc").get()
            ]).then(selectOptions => {
              let profilesData = [];
              selectOptions[0].forEach(doc => {
                let data = doc.data();
                data.id = doc.id;
                profilesData.push(data);
              });

              this.setState(
                {
                  table: existing,
                  action: action,
                  busy: false,
                  profiles: profilesData
                },
                () => {}
              );
            });
          } else {
            Promise.all([
              this.profilesRef.orderBy("lastName", "asc").get()
            ]).then(selectOptions => {
              let profilesData = [];
              selectOptions[0].forEach(doc => {
                let data = doc.data();
                data.id = doc.id;
                profilesData.push(data);
              });

              this.setState(
                {
                  action: "Add",
                  busy: false,
                  profiles: profilesData
                },
                () => {}
              );
            });
          }
        })
        .catch(err => {
          console.log("Error getting documents", err);
        });
    } else {
      let profilesRef = fireStore().collection("profiles");
      Promise.all([profilesRef.orderBy("lastName", "asc").get()]).then(
        selectOptions => {
          let profilesData = [];
          selectOptions[0].forEach(doc => {
            let data = doc.data();
            data.id = doc.id;
            profilesData.push(data);
          });
          this.setState(
            {
              action: "Add",
              busy: false,
              profiles: profilesData
            },
            () => {}
          );
        }
      );
      this.setState({ action: action, busy: false });
      this.setState({ action: action, busy: false });
    }
  }
  handleClose() {
    this.setState({ showSuccess: false, openDelete: false }, () => {
      this.props.history.push("/tables");
    });
  }
  handleChange(event) {
    let newState = {};
    let formName = event.target.name.replace(this.timestamp, "");

    newState[formName] = event.target.value;
    let currentState = Object.assign({}, this.state.table, newState);
    this.setState({ table: currentState }, () => {
      if (this.refs.form) {
        let omitKeys = [""];
        if (
          isEqual(
            omit(this.state.table, omitKeys),
            omit(this.defaultState.table, omitKeys)
          )
        ) {
          this.setState({ disabled: true });
        } else {
          this.refs.form.isFormValid().then(r => {
            this.setState({ disabled: !r });
          });
        }
      }
    });
  }

  checkForUnique() {
    let itemRef = fireStore().collection(this.collectionName);
    if (
      this.state.table.name.toLowerCase() !==
      this.defaultState.table.name.toLowerCase()
    ) {
      return itemRef
        .where("lc_name", "==", this.state.table.name.toLowerCase())
        .get()
        .then(snapshot => {
          if (snapshot.empty) {
            return true;
          }
        })
        .catch(err => {
          console.log("Error getting documents", err);
        });
    } else {
      return Promise.resolve(true);
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    this.checkForUnique().then(r => {
      if (r) {
        let data = {
          name: this.state.table.name,
          lc_name: this.state.table.name.toLowerCase(),
          updated_at: fireStore.FieldValue.serverTimestamp(),
          judges: this.state.table.judges,
          last_updated_by: this.props.user.uid
        };
        if (this.state.action === "Add") {
          data.created_at = fireStore.FieldValue.serverTimestamp();
          data.created_user = this.props.user.uid;
        }
        data.judges_uids = map(this.state.table.judges, (item, key) => {
          return item.profile_id;
        });
        this.setState({ busy: true }, () => {
          let theRef;
          if (this.state.action === "Add") {
            theRef = fireStore()
              .collection(this.collectionName)
              .add(data);
          } else {
            theRef = fireStore()
              .collection(this.collectionName)
              .doc(this.state.table.id)
              .set(data); //edit
          }
          theRef
            .then(r => {
              this.setState({
                busy: false,
                disabled: true,
                error: false,
                showSuccess: true
              });
            })
            .catch(error => {
              // An error happened.
              this.setState({
                error: error.message,
                busy: false,
                disabled: true,
                showSuccess: false
              });
            });
        });
        this.refs.form.resetValidations();
      } else {
        this.setState({
          error:
            "A table with that name already exists, please use a unique table name",
          busy: false,
          disabled: true,
          showSuccess: false
        });
        this.refs.form.resetValidations();
      }
    });
  }
  openDeleteDialog() {
    this.setState({ openDelete: true });
  }
  deleteItem() {
    return fireStore()
      .collection(this.collectionName)
      .doc(this.state.table.id)
      .delete()
      .then(r => {
        this.props.history.push("/tables");
      });
  }
  gotoRoute(route) {
    this.props.history.push(route);
  }

  addJudge() {
    let table = this.state.table;
    let judges = [...table.judges];
    judges.push({
      profile_id: "",
      order: this.state.table.judges.length + 1
    });
    table.judges = judges;
    this.setState({ table: table }, () => {
      let omitKeys = [];
      if (
        isEqual(
          omit(this.state.table.judges, omitKeys),
          omit(this.defaultState.table.judges, omitKeys)
        )
      ) {
        this.setState({ disabled: true });
      } else {
        this.refs.form.isFormValid().then(r => {
          this.setState({ disabled: false });
        });
      }
    });
  }
  removeJudge(index) {
    let table = this.state.table;
    let judges = map(table.judges, (item, key) => {
      return Object.assign({}, item);
    });
    if (judges.length > 0) {
      judges.splice(index, 1);
      table.judges = judges;

      this.setState({ table: table }, () => {
        let omitKeys = [];
        if (
          isEqual(
            omit(this.state.table.judges, omitKeys),
            omit(this.defaultState.table.judges, omitKeys)
          )
        ) {
          this.setState({ disabled: true });
        } else {
          this.refs.form.isFormValid().then(r => {
            this.setState({ disabled: false });
          });
        }
      });
    }
  }
  handleWineselectChange(event, key, uid) {
    let table = this.state.table;
    let judges = [...table.judges];

    if (uid === "president") {
      judges = map(judges, (item, key) => {
        delete item.president;
        return item;
      });
      judges[key][uid] = event.target.value;
    } else {
      judges[key][uid] = event.target.value;
    }

    if (uid === "profile_id") {
      let theProfile = filter(this.state.profiles, (item, key) => {
        return item.id === event.target.value;
      });
      if (theProfile.length) {
        judges[key]["profiles"] = pick(theProfile[0], [
          "firstName",
          "lastName",
          "id"
        ]);
      }
    }

    table.judges = judges;

    this.setState({ table: table }, () => {
      let omitKeys = [];
      if (
        isEqual(
          omit(this.state.table.judges, omitKeys),
          omit(this.defaultState.table.judges, omitKeys)
        )
      ) {
        this.setState({ disabled: true });
      } else {
        this.refs.form.isFormValid().then(r => {
          this.setState({ disabled: false });
        });
      }
    });
  }

  render() {
    const { classes } = this.props;
    let error = null;
    if (this.state.error) {
      error = <div className="error">{this.state.error}</div>;
    }
    let deletebtn = null;
    if (this.state.action === "Edit") {
      deletebtn = (
        <Button
          onClick={this.openDeleteDialog}
          className="delete-btn"
          variant="contained"
          color="primary"
          type="button"
        >
          Delete Table
        </Button>
      );
    }

    let usedProfiles = map(this.state.table.judges, (i, k) => {
      return i.profile_id;
    });
    let profilesOptions = this.state.profiles.map((item, key) => {
      if (usedProfiles.includes(item.id)) {
        return (
          <MenuItem dense={true} disabled key={key} value={item.id}>
            {item.lastName}, {item.firstName}
          </MenuItem>
        );
      } else {
        return (
          <MenuItem dense={true} key={key} value={item.id}>
            {item.lastName}, {item.firstName}
          </MenuItem>
        );
      }
    });

    var tableItems = this.state.table.judges.map((item, key) => {
      return (
        <li key={key} className="tasting-item">
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={1}>
              <Radio
                checked={item.president === "true"}
                onChange={event => {
                  this.handleWineselectChange(event, key, "president");
                }}
                value={true}
                name="radio-button-demo"
                inputProps={{ "aria-label": true }}
              />
            </Grid>
            <Grid item xs={1}>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  id={"Judge_Order_" + key}
                  value={item.order}
                  onChange={event => {
                    this.handleWineselectChange(event, key, "order");
                  }}
                  type="number"
                />
              </FormControl>
            </Grid>
            <Grid item xs={9}>
              <FormControl fullWidth>
                <Select
                  variant="outlined"
                  labelId={"Judge_" + key}
                  id={"Judge_Select_" + key}
                  value={item.profile_id}
                  displayEmpty
                  onChange={event => {
                    this.handleWineselectChange(event, key, "profile_id");
                  }}
                >
                  <MenuItem dense={true} value="">
                    Select Judge
                  </MenuItem>
                  {profilesOptions}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1}>
              <Fab
                color="primary"
                size="small"
                aria-label="add"
                onClick={() => {
                  this.removeJudge(key);
                }}
                className="remove-tasting-btn"
              >
                -
              </Fab>
            </Grid>
          </Grid>
        </li>
      );
    });
    let tasting_content;
    if (this.state.table.judges.length) {
      tasting_content = (
        <div>
          <Grid container className="grid-header" spacing={3}>
            <Grid item xs={1}>
              President
            </Grid>
            <Grid item xs={1}>
              Order
            </Grid>
            <Grid item xs={9}>
              Judge
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
          <ul className="wines-list">{tableItems}</ul>
        </div>
      );
    } else {
      tasting_content = <p>There are no judges added.</p>;
    }
    let saveText = this.state.action;
    if (this.state.action === "Edit") {
      saveText = "Save";
    }

    return (
      <div className="logged-in-with-header">
        <div className="login-form dashboard">
          <div className="admin tables">
            <Header
              user={this.props.user}
              profile={this.props.profile}
              developer={this.props.developer}
              administrator={this.props.administrator}
            />
            <div className="page-content">
              <Link className="back-link" to="/tables">
                <i className="fas fa-chevron-left"></i> Back
              </Link>
              <div className="actions">{deletebtn}</div>
              <h1>{this.state.action} Table</h1>
              <div className="form">
                {error}
                <ValidatorForm
                  ref="form"
                  onSubmit={this.handleSubmit}
                  onError={errors => console.log(errors)}
                >
                  <div className="form-row">
                    <TextValidator
                      fullWidth
                      name={"name" + this.timestamp}
                      value={this.state.table.name}
                      onChange={this.handleChange}
                      label="Enter a Table Name"
                      validators={["required"]}
                      errorMessages={["This field is required"]}
                    />
                  </div>

                  <div className="wines">
                    <h2>Judges</h2>
                    {tasting_content}
                    <Button
                      className="add-tasting-btn"
                      onClick={this.addJudge}
                      variant="contained"
                      type="button"
                    >
                      + Add
                    </Button>
                  </div>

                  <div className="form-btns">
                    <Grid container spacing={3}>
                      <Grid item xs={8}></Grid>
                      <Grid item xs={2}>
                        <Button
                          className="cancel-btn"
                          onClick={() => {
                            this.gotoRoute("/tables");
                          }}
                          variant="contained"
                          type="button"
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          className="login-btn"
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={this.state.disabled}
                        >
                          {saveText} Table
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </ValidatorForm>
              </div>
            </div>
            <Backdrop className={classes.backdrop} open={this.state.busy}>
              <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog
              onClose={this.handleClose}
              disableBackdropClick
              aria-labelledby="simple-dialog-title"
              open={this.state.showSuccess}
            >
              <DialogContent>
                Success, your Table <strong>'{this.state.table.name}'</strong>{" "}
                has been updated.
              </DialogContent>
              <DialogActions className="logout-actions">
                <Button autoFocus onClick={this.handleClose}>
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              onClose={this.handleCloseDelete}
              aria-labelledby="simple-dialog-title"
              open={this.state.openDelete}
            >
              <DialogTitle>Delete</DialogTitle>
              <DialogContent>
                Are you sure you want to delete{" "}
                <strong>'{this.state.table.name}'</strong>. This cannot be
                undone.
              </DialogContent>
              <DialogActions className="logout-actions">
                <Button autoFocus onClick={this.handleCloseDelete}>
                  Cancel
                </Button>
                <Button autoFocus onClick={this.deleteItem}>
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(TablesEdit);