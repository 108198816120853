import React, { Component } from "react";
import logo from "../images/logo.png";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { firebaseAuth } from "../constants";
import Header from "../components/header";

class Confirmemail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmation: false
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resendConfirmation = this.resendConfirmation.bind(this);
  }

  componentWillUnmount() {}
  componentDidMount() {}

  handleChange(event) {
    let newState = {};
    let formName = event.target.name.replace(this.timestamp, "");

    newState[formName] = event.target.value;
    this.setState(newState, () => {
      if (this.refs.form) {
        this.refs.form.isFormValid().then(r => {
          this.setState({ disabled: !r });
        });
      }
    });
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  resendConfirmation() {
    this.props.user
      .sendEmailVerification({
        url:
          "https://www.desembarco.io/login?user=" +
          firebaseAuth().currentUser.email
      })
      .then(r => {
        // Email sent.
        console.log("r", r);
        this.setState({ showConfirmation: true }, () => {});
      })
      .catch(er => {
        console.log("er", er);
      });
  }

  render() {
    if(this.state.showConfirmation) {
      return (
      <div className="logged-in-with-header">
        <Header
          user={this.props.user}
          profile={this.props.profile}
          developer={this.props.developer}
          administrator={this.props.administrator}
        />
        <div className="login-form register-form">
          <div className="logo-wrapper">
            <Link to="/">
              <img src={logo} alt="Welcome" />
            </Link>
          </div>
          <p>Please check your email inbox for a confirmation email.  <br/>Please make sure you check any spam or junk email inboxes.</p>
        </div>
      </div>
    );
    } else {
      return (
      <div className="logged-in-with-header">
        <Header
          user={this.props.user}
          profile={this.props.profile}
          developer={this.props.developer}
          administrator={this.props.administrator}
        />
        <div className="login-form register-form">
          <div className="logo-wrapper">
            <Link to="/">
              <img src={logo} alt="Welcome" />
            </Link>
          </div>
          <p>
            Please confirm your email before you can continue. A confirmation
            email was sent to '{this.props.user.email}' during registration.
          </p>
          <p>
            If you need to generate another confirmation email, please click the
            button below.
          </p>
          <Button
            className="login-btn"
            variant="contained"
            color="primary"
            onClick={this.resendConfirmation}
          >
            Resend Confirmation Email
          </Button>
        </div>
      </div>
    );
    }
    
  }
}

export default Confirmemail;