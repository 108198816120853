import React, { useState} from "react";
import Grid from "@material-ui/core/Grid";
import map from "lodash/map";
import { BarChart, PieChart } from 'react-charts-d3';

export const AverageByGroup = (props) => {
  // You can use Hooks here!
  const [facets, setFacet] = useState(props.facets);
  
  let averages = null;
  if(facets){
     averages = map(facets.group,(qkey, k) => {
       if(k === 'undefined') {
         k = 'Unspecified';
       }
       return (<Grid className="avg-item" item key={k}>
         <div className="avg-label">{k} <span className="meta">{qkey.sum}/{qkey.count}</span></div>
         <div className="avg-value">{(qkey.sum/qkey.count).toFixed(2)}</div>
       </Grid>)
     });
       
       
  }

  let averagesGroup = null;
  if(props.facets){
    averagesGroup = (<Grid container>{averages}</Grid>)
  }

  return (<div className="report-section">
          <div className="report-section-inner">
          <h2>Average by Group</h2>
            {averagesGroup}
          </div>
        </div>);
}


export const AverageByCommune = (props) => {
  // You can use Hooks here!
  const [facets, setFacet] = useState(props.facets);
  
  
  let averagesCommune = null;
  if(facets.commune){
     averagesCommune = map(facets.commune,(qkey, k) => {
       if(k === 'undefined') {
         k = 'Unspecified';
       }
       return (<Grid className="avg-item" item key={k}>
         <div className="avg-label">{k} <span className="meta">{qkey.sum}/{qkey.count}</span></div>
         <div className="avg-value">{(qkey.sum/qkey.count).toFixed(2)}</div>
       </Grid>)
     });
       
       
  }

  let averagesByCommune = null;
  if(facets.commune){
    averagesByCommune = (<Grid container>{averagesCommune}</Grid>)
  }

  return (<div className="report-section">
            <div className="report-section-inner">
            <h2>Average by Commune</h2>
            {averagesByCommune}
            </div>
          </div>);
}



export const MedalsByPercantage = (props) => {
  // You can use Hooks here!
  const [facets, setFacet] = useState(props.facets);


  let medalsByPercent = null;
  let formatValue = (d) => {
    return Math.round(d) + '%';
  };
  if(facets.medals_percents_pie_data){
    medalsByPercent = (<PieChart height={500} width={500} valueFormatter={formatValue} data={facets.medals_percents_pie_data} />);
  }
  return (<div className="report-section">
        <div className="report-section-inner">
        <h2>Percentages by Medal</h2>
         {medalsByPercent}
        </div>
      </div>);
}