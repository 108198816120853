import React, { Component } from 'react';
import {firebaseAuth, fireStore} from '../constants';
import Grid from '@material-ui/core/Grid';
import Header from '../components/header';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
const styles = theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
});

class MyAccount extends Component {
  constructor(props) {
    super(props);
    
    this.defaultState = {
      user:false,
      updatePassword: false,
      firstName: '',
      lastName: '',
      password: '',
      current_password: '',
      confirmPassword: '',
      disabled: true,
      error: false,
      showConfirmation: false,
      busy: true,
    };
    this.state = this.defaultState
    this.timestamp = new Date().getTime(); //used for name

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updatePasswordCheck = this.updatePasswordCheck.bind(this);
    this.setDefaultState = this.setDefaultState.bind(this);
  }
  componentWillUnmount() {
    ValidatorForm.removeValidationRule('isPasswordMatch');
    this.removeListener = null;
  }
  componentDidMount() {
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
        if (value !== this.state.password) {
            return false;
        }
        return true;
    });
    this.setDefaultState();  


  }
  setDefaultState() {
    let currentState = Object.assign({}, this.state,this.props.profile);
    currentState.user = this.props.user;
    currentState.busy = false;
    this.setState(currentState);
  }
  updatePasswordCheck(event) {
    let update = {
       updatePassword: event.target.checked
    };
    if(!event.target.checked) {
      update.password = '';
      update.current_password = '';
      update.confirmPassword = '';
    }
    this.setState(update, () => {
      this.refs.form.isFormValid().then((r) => {
        this.setState({disabled: !r});
      });
    });
    
  }
  handleChange(event) {   
    let newState = {};
    let formName = event.target.name.replace(this.timestamp, '');

    newState[formName] = event.target.value;
    this.setState(newState, () => {
      if(this.refs.form) {
        let omitKeys = ['updatePassword', 'user', 'disabled'];
        if(isEqual(omit(this.state,omitKeys),omit(this.defaultState,omitKeys))) {
          this.setState({disabled: true})
        } else {
          this.refs.form.isFormValid().then((r) => {
            this.setState({disabled: !r});
          });  
        }
        
      }

    });
  }

  handleSubmit(event) {
    event.preventDefault();
    let data = { 
      'firstName': this.state.firstName,
      'lastName': this.state.lastName,
      'updated_at': fireStore.FieldValue.serverTimestamp()
    };
    this.setState({ 'busy': true}, () => {
      fireStore().collection("profiles").doc(this.state.user.uid).set(data).then((r) =>{ 

        if(this.state.updatePassword) {
          var credential = firebaseAuth.EmailAuthProvider.credential(
            this.state.user.email,
            this.state.current_password
          );
          this.state.user.reauthenticateAndRetrieveDataWithCredential(credential).then((r) => {
            // User re-authenticated.
            firebaseAuth().currentUser.updatePassword(this.state.password).then((r) => {
              this.setState({'busy': false, disabled: true, updatePassword: false, error: false}, () =>{
                this.props.updateProfile(this.state.user);
              });
            });

          }).catch((error) => {
            // An error happened.
            this.setState({error: error.message, 'busy': false, disabled: true});
          });
        } else {
          this.props.updateProfile(this.state.user);
          this.setState({'busy': false, disabled: true}, () =>{
            this.setDefaultState();
          });
        }
      });
    });
    this.refs.form.resetValidations();
  }



  render() {
    const { classes } = this.props;
    let error = null;
    if(this.state.error) {
      error = (<div className="error">{this.state.error}</div>);
    }


    let passwordFields = null;
    if(this.state.updatePassword) {
      passwordFields = (<div><div className="form-row">
                <TextValidator 
                  fullWidth 
                  type="password"
                  name={'current_password'+this.timestamp} 
                  value={this.state.current_password} 
                  onChange={this.handleChange}  
                  label="Enter your current password"
                  validators={['required', 'minStringLength:6']}
                  errorMessages={['This field is required', 'Password must be longer than 6 characters']}
                  />
              </div><div className="form-row">
                <TextValidator 
                  fullWidth 
                  type="password"
                  name={'password'+this.timestamp} 
                  value={this.state.password} 
                  onChange={this.handleChange}  
                  label="Enter your new password"
                  validators={['required', 'minStringLength:6']}
                  errorMessages={['This field is required', 'Password must be longer than 6 characters']}
                  />
              </div>
              <div className="form-row">
                <TextValidator 
                  fullWidth 
                  name={'confirmPassword'+this.timestamp} 
                  type="password"
                  value={this.state.confirmPassword} 
                  onChange={this.handleChange}  
                  label="Confirm your new password" 
                  validators={['required', 'isPasswordMatch']}
                  errorMessages={['This field is required', 'Password does not match']}
                />
              </div></div>);
    }

    if(this.state.user) { 
      return (
      <div className="logged-in-with-header">
      <div className="login-form dashboard">
      <div className="my-account">
        <Header user={this.props.user} profile={this.props.profile} developer={this.props.developer} administrator={this.props.administrator}/>
        
        <div className="page-content">
          <div className="my-account-form">
            <h1>My Account</h1> 

            {error}
            <ValidatorForm
                  ref="form"
                  onSubmit={this.handleSubmit}
                  onError={errors => console.log(errors)}
              >
              <div className="form-row">
                <TextValidator 
                  fullWidth 
                  name={'firstName'+this.timestamp} 
                  value={this.state.firstName} 
                  onChange={this.handleChange}  
                  label="Enter your first name"
                  validators={['required']}
                  errorMessages={['This field is required']}
                  />
              </div>
              <div className="form-row">
                <TextValidator 
                  fullWidth 
                  name={'lastName'+this.timestamp} 
                  value={this.state.lastName} 
                  onChange={this.handleChange}  
                  label="Enter your last name"
                  validators={['required', 'minStringLength:1']}
                  errorMessages={['This field is required', 'this field is required']}
                  />
              </div>
              <div className="form-row read-only-row">
                <TextValidator 
                  fullWidth 

                  autoComplete="off"
                  name={'username'+this.timestamp} 
                  value={this.state.user.email} 
                  label="Email (Readonly)"
                  InputProps={{'readOnly': true}}
                  validators={['required', 'isEmail']}
                  errorMessages={['This field is required', 'Check your email format']}
                  />
              </div>
              <div className="form-row form-row-left">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.updatePassword}
                      onChange={this.updatePasswordCheck}
                    />
                  }
                  label="Update Password"
                />
              </div>

              {passwordFields}
              
              <div className="form-btns">
                <Grid container spacing={3}>  
                  <Grid item xs={4}>
                  </Grid>
                  <Grid item xs={4}>
                    <Button className="login-btn" variant="contained" color="primary" type="submit" disabled={this.state.disabled}>
                      Update Account
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                  </Grid>
                </Grid>
                
                
              </div>
            </ValidatorForm>
          </div>
        </div>
        <Backdrop   
          className={classes.backdrop}      
          open={this.state.busy}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      </div>
      </div>
    );
    } else {
      return null;
    }
    
  }
}

export default withStyles(styles)(MyAccount);
//firebase.auth().signOut().then(function() {