import React, { Component } from "react";
import logo from "../images/logo.png";
import { Link } from "react-router-dom";
import Header from "../components/header";

class RegistrationNotOpen extends Component {


  render() {
    let header = null;
    if(this.props.user) {
      header = (<Header
          user={this.props.user}
          profile={this.props.profile}
          developer={this.props.developer}
          administrator={this.props.administrator}
        />);
    }
    return (
      <div className="logged-in-with-header">
        {header}
        <div className="login-form register-form">
          <div className="logo-wrapper">
            <Link to="/">
              <img src={logo} alt="Welcome" />
            </Link>
          </div>
          <p>Sorry, registration is not open.</p>
        </div>
      </div>
    );
  }
}

export default RegistrationNotOpen;