import React, { Component } from 'react';
import {fireStore} from '../constants';
import Grid from '@material-ui/core/Grid';
import Header from '../components/header';
import {
  Link
} from "react-router-dom";
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';

const styles = theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
});


class WinesEdit extends Component {
  constructor(props) {
    super(props);
    this.collectionName = 'wines';
    this.state = {
      user: false,
      profile: false,
      action: '',
      busy: true,
      disabled: true,
      showSuccess: false,
      error: false,
      openDelete: false,
      wine: {
        name: '',
        lc_name: '',
        winery_name: '',
        wine_code: '',
        winery_city: ''
      }
    }
    this.defaultState = {
      user: false,
      profile: false,
      action: '',
      busy: true,
      disabled: true,
      showSuccess: false,
      error: false,
      openDelete: false,
      wine: {
        name: '',
        lc_name: '',
        winery_name: '',
        wine_code: '',
        winery_city: ''
      }
    }
    this.timestamp = new Date().getTime(); //used for name
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.checkForUnique = this.checkForUnique.bind(this);
    this.openDeleteDialog = this.openDeleteDialog.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.handleCloseDelete = this.handleCloseDelete.bind(this);
    this.gotoRoute = this.gotoRoute.bind(this);
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    let action = '';
    if(this.props.match.params.id === 'add'){
      action = 'Add';
    } else {
      action = 'Edit';
    }
    if(action === 'Edit') { 
      let collectionsRef = fireStore().collection(this.collectionName);
      collectionsRef.doc(this.props.match.params.id).get()
        .then((docSnapshot) => {
          if (docSnapshot.exists) {
            let data = docSnapshot.data();
            data.id = docSnapshot.id;
            this.defaultState.wine = Object.assign({}, this.defaultState.wine, data);
            data = Object.assign({}, this.defaultState.wine, data);
            this.setState({wine: data, action: action, busy: false})
          } else {


          }
        })
        .catch(err => {
          console.log('Error getting documents', err);
        });
    } else {
      this.setState({action: action, busy: false})
    }



  }
  handleClose() {
    this.setState({'showSuccess': false}, () => {
      this.props.history.push('/wines');
    });
  }
  handleCloseDelete() {
    this.setState({'openDelete': false}, () => {
      
    });
  }
  handleChange(event) {   
    let newState = {};
    let formName = event.target.name.replace(this.timestamp, '');

    newState[formName] = event.target.value;
    let currentState = Object.assign({}, this.state.wine, newState);
    this.setState({ wine: currentState }, () => {
      if(this.refs.form) {
        let omitKeys = [''];
        if(isEqual(omit(this.state.wine,omitKeys),omit(this.defaultState.wine,omitKeys))) {
          this.setState({disabled: true})
        } else {
          this.refs.form.isFormValid().then((r) => {
            this.setState({disabled: false});
          });  
        }
        
      }

    });
  }

  checkForUnique() {
    let itemRef = fireStore().collection(this.collectionName);
    if(this.state.wine.name.toLowerCase() !== this.defaultState.wine.name.toLowerCase()) {

      return itemRef.where('lc_name', '==', this.state.wine.name).get()
        .then(snapshot => {
          if (snapshot.empty) {
            return true;
          }  
        })
        .catch(err => {
          console.log('Error getting documents', err);
        });
    } else {
      return Promise.resolve(true);
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    this.checkForUnique().then((r) => {
      if(r) {
        let data = { 
          'name': this.state.wine.name,
          'lc_name': this.state.wine.name.toLowerCase(),
          'winery_name': this.state.wine.winery_name,
          'wine_code': this.state.wine.wine_code,
          'winery_city':this.state.wine.winery_city,
          'updated_at': fireStore.FieldValue.serverTimestamp(),
          'last_updated_by': this.props.user.uid
        };
        if(this.state.action === 'Add') {
          data.created_at = fireStore.FieldValue.serverTimestamp();
          data.created_user = this.props.user.uid;
        }
        this.setState({ 'busy': true}, () => {
          let theRef;
          if(this.state.action === 'Add') {
            theRef = fireStore().collection(this.collectionName).add(data);
          } else {
            theRef = fireStore().collection(this.collectionName).doc(this.state.wine.id).set(data); //edit
          }
          theRef.then((r) =>{ 
            this.setState({'busy': false, disabled: true, error: false, showSuccess: true});
          }).catch((error) => {
            // An error happened.
            this.setState({error: error.message, 'busy': false, disabled: true, showSuccess: false});
          });
        });
        this.refs.form.resetValidations();
      } else {
        this.setState({error: 'A wine with that name already exists, please use a unique wine name', 'busy': false, disabled: true, showSuccess: false});
        this.refs.form.resetValidations();
      }
    });
    
  }

  openDeleteDialog() {
    this.setState({ openDelete: true });
  }
  deleteItem() {
    return fireStore().collection(this.collectionName).doc(this.state.wine.id).delete().then((r) => {
      this.props.history.push('/wines');
    });
  }
  gotoRoute(route) {
    this.props.history.push(route);
  }

  render() {
    const { classes } = this.props;
    let error = null;
    if(this.state.error) {
      error = (<div className="error">{this.state.error}</div>);
    }
    let deletebtn = null;
    if(this.state.action === 'Edit') {
      deletebtn = (<Button onClick={this.openDeleteDialog} className="delete-btn" variant="contained" color="primary" type="button">
                      Delete Wine
                    </Button>)
    }
    return (<div className="logged-in-with-header">
      <div className="login-form dashboard">
    	<div className="admin tables">
        <Header user={this.props.user} profile={this.props.profile} developer={this.props.developer} administrator={this.props.administrator}/>
        <div className="page-content">
          <Link className="back-link" to="/wines"><i className="fas fa-chevron-left"></i> Back</Link>
          <div className="actions">
            {deletebtn}
          </div>
          <h1>{this.state.action} wines</h1>
          <div className="form">
            {error}
            <ValidatorForm
                  ref="form"
                  onSubmit={this.handleSubmit}
                  onError={errors => console.log(errors)}
              >
              <div className="form-row">
                <TextValidator 
                  fullWidth 
                  name={'name'+this.timestamp} 
                  value={this.state.wine.name} 
                  onChange={this.handleChange}  
                  label="Enter a Wine Name"
                  validators={['required']}
                  errorMessages={['This field is required']}
                  />
              </div>
              
              <div className="form-row">
                <TextValidator 
                  fullWidth 
                  name={'wine_code'+this.timestamp} 
                  value={this.state.wine.wine_code} 
                  onChange={this.handleChange}  
                  label="Enter a Winery Code"
                  validators={['required']}
                  errorMessages={['This field is required']}
                  />
              </div>


              <div className="form-row">
                <TextValidator 
                  fullWidth 
                  name={'winery_name'+this.timestamp} 
                  value={this.state.wine.winery_name} 
                  onChange={this.handleChange}  
                  label="Enter a Winery Name"
                  validators={['required']}
                  errorMessages={['This field is required']}
                  />
              </div>


              <div className="form-row">
                <TextValidator 
                  fullWidth 
                  name={'winery_city'+this.timestamp} 
                  value={this.state.wine.winery_city} 
                  onChange={this.handleChange}  
                  label="Enter a Winery City"
                  validators={['required']}
                  errorMessages={['This field is required']}
                  />
              </div>

              <div className="form-btns">
                <Grid container spacing={3}>  
                  <Grid item xs={2}>
                    
                  </Grid>
                  
                  <Grid item xs={6}>
                  </Grid>
                  <Grid item xs={2}>
                    <Button className="cancel-btn" onClick={() => { this.gotoRoute('/wines'); }} variant="contained" type="button">
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={2}>
                    <Button className="login-btn" variant="contained" color="primary" type="submit" disabled={this.state.disabled}>
                      {this.state.action} Wine
                    </Button>
                  </Grid>
                </Grid>
                
                
              </div>
            </ValidatorForm>
          </div>
        </div>
        <Backdrop   
          className={classes.backdrop}      
          open={this.state.busy}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Dialog onClose={this.handleClose} disableBackdropClick aria-labelledby="simple-dialog-title" open={this.state.showSuccess}>
            <DialogContent>Success, your Wines <strong>'{this.state.wine.name}'</strong> has been updated.</DialogContent>
            <DialogActions className="logout-actions">
              <Button autoFocus onClick={this.handleClose}>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        <Dialog onClose={this.handleCloseDelete} aria-labelledby="simple-dialog-title" open={this.state.openDelete}>
          <DialogTitle>Delete</DialogTitle>
          <DialogContent>Are you sure you want to delete <strong>'{this.state.wine.name}'</strong>. This cannot be undone.</DialogContent>
          <DialogActions className="logout-actions">
            <Button autoFocus onClick={this.handleCloseDelete}>
              Cancel
            </Button>
            <Button autoFocus onClick={this.deleteItem}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      </div>
      </div>
    );
  }
}

export default withStyles(styles)(WinesEdit);