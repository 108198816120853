import React, { Component } from "react";
import logo from "../images/logo.png";
import { Link } from "react-router-dom";
import Header from "../components/header";

class LoginNotOpen extends Component {
    //this.props.history.push("/questions");
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    console.log('this', this);
    if(this.props.user){

    } else {
      this.props.history.replace("/login");
    }
  }
  render() {
    let header = null;
    if(this.props.user) {
      header = (<Header
          user={this.props.user}
          profile={this.props.profile}
          developer={this.props.developer}
          administrator={this.props.administrator}
        />);
    }

    return (
      <div className="logged-in-with-header">
        {header}
        <div className="login-form register-form">
          <div className="logo-wrapper">
            <Link to="/">
              <img src={logo} alt="Welcome" />
            </Link>
          </div>
          <p>Please find your assigned judging table at the event on February 5th.</p>
        </div>
      </div>
    );
  }
}

export default LoginNotOpen;