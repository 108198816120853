import React, { Component } from 'react';
import {fireStore} from '../constants';
import Fab from '@material-ui/core/Fab';
import Header from '../components/header';
import AddIcon from '@material-ui/icons/Add';
import Backdrop from '@material-ui/core/Backdrop';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Link
} from "react-router-dom";
const styles = theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
});
class Wines extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user:false,
      wines: [],
      busy: true
    }

    this.gotoRoute = this.gotoRoute.bind(this);

  }
  componentWillUnmount() {
  }
  componentDidMount() {

    let tablesRef = fireStore().collection('wines');
    tablesRef.orderBy('lc_name', 'asc').get()
      .then((snapshot) => {
        let tableData = [];
        snapshot.forEach((doc) => {
          let data = doc.data();
          data.id = doc.id;
          tableData.push(data);
        });
       
        this.setState({wines: tableData,  busy: false});
      })
      .catch(err => {
        console.log('Error getting documents', err);
        this.setState({wines: [],  busy: false});
      });


  }

  gotoRoute(route) {
    this.props.history.push(route);
  }

  render() {
    const { classes } = this.props;
    var tableItems = this.state.wines.map(function(item) {
      return (
        <li key={item.id}>
          <Link to={'/wines/'+item.id}>{item.name} - {item.winery_name} | {item.wine_code}</Link>
        </li>
      );
    });
    let content;
    if(this.state.wines.length) {
      content = (<div>
          <h3>{this.state.wines.length} Total Wines</h3><ul>
            {tableItems}
          </ul></div>);
    } else {
      content = (<p>There are no wines added.</p>);
    }
 
      return (<div className="logged-in-with-header">
      <div className="login-form dashboard">
        <div className="admin tables">
          <Header user={this.props.user} profile={this.props.profile} developer={this.props.developer} administrator={this.props.administrator}/>
          <div className="page-content">
            <div className="actions">
              <Fab onClick={()=>{this.gotoRoute('/wines/add')}} size="small" color="primary" aria-label="add">
                <AddIcon />
              </Fab>
            </div>
            <h1>Manage Wines</h1>
            <div className="table-content">
              {content}
            </div>
          </div>
          <Backdrop   
            className={classes.backdrop}      
            open={this.state.busy}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
        </div>
        </div>
      );
    
  }
}

export default withStyles(styles)(Wines);