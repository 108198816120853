import React, { Component } from "react";
import { fireStore, fireBase } from "../constants";
import Grid from "@material-ui/core/Grid";
import Header from "../components/header";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Backdrop from "@material-ui/core/Backdrop";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ValidatorForm } from "react-material-ui-form-validator";
import omit from "lodash/omit";
import isEqual from "lodash/isEqual";
import map from "lodash/map";
import each from "lodash/each";
import filter from "lodash/filter";
import groupBy from "lodash/groupBy";
import reduce from "lodash/reduce";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";

import TextareaAutosize from '@material-ui/core/TextareaAutosize';

const styles = theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
});

// Inspired by blueprintjs
function StyledRadio(props) {
  return <Radio disableRipple color="default" {...props} />;
}

class TastingEdit extends Component {
  constructor(props) {
    super(props);
    this.collectionName = "flights";
    this.state = {
      action: "Add",
      isPresident: false,
      busy: true,
      disabled: true,
      showSuccess: false,
      error: false,
      openDelete: false,
      questions: false,
      submission: false,
      question_id: false,
      submitted: false,
      group_name: '',
      table_name: '',
      flight_name: '',
      tasting_order: '',
      dialogAction: '',
      tasting_notes: '',
      tablePresidentMsgModal: false
    };
    this.defaultState = {
      action: "Add",
      isPresident: false,
      busy: true,
      disabled: true,
      showSuccess: false,
      error: false,
      openDelete: false,
      questions: false,
      submission: false,
      question_id: false,
      submitted: false,
      group_name: '',
      table_name: '',
      flight_name: '',
      tasting_order: '',
      dialogAction: '',
      tasting_notes: '',
      tablePresidentMsgModal: false
    };
    this.timestamp = new Date().getTime(); //used for name
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.checkForUnique = this.checkForUnique.bind(this);
    this.gotoRoute = this.gotoRoute.bind(this);
    this.checkValidation = this.checkValidation.bind(this);
    this.renderPresidentModalActions = this.renderPresidentModalActions.bind(this);

    this.getData = this.getData.bind(this);

    this.confirm = this.confirm.bind(this);
    this.setEdit = this.setEdit.bind(this);
    this.renderPageH1 = this.renderPageH1.bind(this);
    this.reject = this.reject.bind(this);
    this.confirmRejection = this.confirmRejection.bind(this);
    this.confirmConfirmation = this.confirmConfirmation.bind(this);
    this.handleTextAreaChange = this.handleTextAreaChange.bind(this);

    this.collectionsRef = fireStore().collection(this.collectionName);
    this.questionsRef = fireStore().collection("questions");
    this.submissionsRef = fireStore().collection("submissions");
    
    this.tablesRef = fireStore().collection("tables");
    this.flightsRef = fireStore().collection("flights");
    this.winesRef = fireStore().collection("wines");
  }
  handleTextAreaChange(event) {
    this.setState({tasting_notes: event.target.value});
  }
  componentWillUnmount() {
    if(this.state.isPresident) {
      let fBpath = this.props.match.params.table_id+'/'+this.props.match.params.flight_id+'/'+this.props.match.params.wine_code;
      fireBase().ref(fBpath).update({
        ready: false
      });
    }
    let fBpath = this.props.match.params.table_id+'/'+this.props.match.params.flight_id+'/'+this.props.match.params.wine_code;
    let workflowFBRef = fireBase().ref(fBpath);
    workflowFBRef.off('value', this.handleRt);
  }
  componentDidMount() {
    this.getData();
  }

  setTastingToReady(value) {
    if(this.state.isPresident) {
      console.log('value', value);
      let fBpath = this.props.match.params.table_id+'/'+this.props.match.params.flight_id+'/'+this.props.match.params.wine_code;
      fireBase().ref(fBpath).update({
        ready: value
      });
    }
  }

  getData() {
    return Promise.all([this.getWine(), this.getFlight(), this.getTable()]).then((preloadData) => {

      let isValid = true;
      each(preloadData, (item, key) => {
        if(!item) {
          isValid = false;
        }
      }); 

      if(isValid) {

           /*         console.log('preloadData', preloadData);
         /*      .where("wine_code", "==", this.props.match.params.wine_code)
      .where("table_id", "==", this.props.match.params.table_id)
      .where("flight_id", "==", this.props.match.params.flight_id)*/
  /*      console.log('this', this);
        fireBase().ref(this.props.match.params.table_id+'/'+this.props.match.params.flight_id+'/'+this.props.match.params.wine_code).set({
          ready: true
        });

        var starCountRef = firebase.database().ref('posts/' + postId + '/starCount');
starCountRef.on('value', function(snapshot) {
  updateStarCount(postElement, snapshot.val());
});

*/      
       
        //}

        return this.getQuestion().then(d => {
           let fBpath = this.props.match.params.table_id+'/'+this.props.match.params.flight_id+'/'+this.props.match.params.wine_code;
        //if(this.state.president) {

        //} else {
          let workflowFBRef = fireBase().ref(fBpath);
          this.handleRt = (snapshot) => {
            console.log('workflowFBRef', snapshot.val());
            let test = false;
            let rejected = false;
            if(snapshot.val()) {
              test = snapshot.val().ready;
              rejected = snapshot.val().rejected;
            }
            this.setState({'tablePresidentMsgModal': !test, rejected: rejected}, () => {
              if(rejected && d.rejected != rejected) {
                this.handleSubmit(false, false);
              }
            });
          };

          workflowFBRef.on('value', this.handleRt);
          if (d) {
            let test = false;
            each(d.questions, (i, k) => {
              each(i, (item, key) => {
                if (!item.value) {
                  test = true;
                }
              });
            });
            this.setState(
              {
                questions: d.questions,
                question_id: d.id,
                submission: d,
                action: "Edit",
                busy: false,
                submitted: !test
              },
              () => {}
            );
          } else {
            this.getQuestions().then(data => {
              this.setState(
                {
                  questions: data,
                  question_id: false,
                  busy: false,
                  submitted: false
                },
                () => {}
              );
            });
          }
        });
      } else {

        this.props.history.replace("/404");
      }
    });
  }
  getWine() {
    return this.winesRef
      .where("wine_code", "==", this.props.match.params.wine_code)
      .get()
      .then((snapshot) => {
        if(!snapshot.empty) {
          let data = snapshot.docs[0].data();
          this.winery_group = data.winery_group;
          this.winery_city = data.winery_city;
          this.winery_name = data.winery_name;
        }
        return !snapshot.empty;
      });
  }
  getFlight() {
    return this.flightsRef.doc(this.props.match.params.flight_id).get().then((snapshot) => {
        if(snapshot.exists) {
          let data = snapshot.data();
          let getOrder = filter(data.wines, (item,key) => {
            return item.wine.wine_code === this.props.match.params.wine_code;
          });
          if(getOrder.length){
            this.setState({ 'group_name': data.group_name, 'flight_name': data.name, 'tasting_order': getOrder[0].order});
            return snapshot.exists;
          } else {
            return false;
          }
        }
        return snapshot.exists;
      });
  }
  getTable() {
    return this.tablesRef.doc(this.props.match.params.table_id).get().then((snapshot) => {
        if(snapshot.exists) {
          let data = snapshot.data();
          console.log('data', data);
          if(data.judges_uids.indexOf(this.props.user.uid) !== -1) {
            let isPresident = filter(data.judges, (item, key) => {
              return  item.profile_id === this.props.user.uid && item.president;
            });
            this.setState({ 'table_name': data.name, 'isPresident': isPresident.length});
            return true;
          } else {
            return false;
          }
        }
        return snapshot.exists;
      });
  }
  getQuestion() {
    return this.submissionsRef
      .where("last_updated_by", "==", this.props.user.uid)
      .where("wine_code", "==", this.props.match.params.wine_code)
      .where("table_id", "==", this.props.match.params.table_id)
      .where("flight_id", "==", this.props.match.params.flight_id)
      .get()
      .then((snapshot) => {
        let userData = false;
        snapshot.forEach(doc => {
          let data = doc.data();
          data.id = doc.id;
          userData = data;
        });

        return userData;
      });
  }
  getQuestions() {
    return this.questionsRef
      .orderBy("order_int", "asc")
      .get()
      .then(snapshot => {
        let userData = [];
        snapshot.forEach(doc => {
          let data = doc.data();
          data.id = doc.id;
          userData.push(data);
        });
        userData = groupBy(userData, b => {
          return b.group;
        });

        return userData;
      });
  }

  handleClose() {
    this.setState({ showSuccess: false, dialogAction: '' }, () => {
      this.getData();
    });
  }

  checkValidation() {
    let test = false;
    each(this.state.questions, (i, k) => {
      each(i, (item, key) => {
        if (!item.value) {
          test = true;
        }
      });
    });
    this.setState({ disabled: test });
  }

  handleChange(event, group, name) {
    let newState = {};
    let formName = event.target.name.replace(this.timestamp, "");

    let currentState = Object.assign({}, this.state.questions, newState);
    each(currentState[group], (item, key) => {
      if (item.id === formName) {
        delete item.value;
      }
      if (item.id === formName && item.name === name) {
        item.value = event.target.value;
      }
    });
    this.setState({ questions: currentState }, () => {
      if (this.refs.form) {
        let omitKeys = [""];
        if (
          isEqual(
            omit(this.state.questions, omitKeys),
            omit(this.defaultState.questions, omitKeys)
          )
        ) {
          this.setState({ disabled: true });
        } else {
          this.checkValidation();
        }
      }
    });
  }

  checkForUnique() {
    return Promise.resolve(true);
  }

  handleSubmit(event, redirect = true) {
    if (event) {
      event.preventDefault();
    }
    this.checkForUnique().then(r => {
      if (r) {
        let data = {
          updated_at: fireStore.FieldValue.serverTimestamp(),
          last_updated_by: this.props.user.uid,
          questions: this.state.questions,
          wine_code: this.props.match.params.wine_code,
          table_id: this.props.match.params.table_id,
          flight_id: this.props.match.params.flight_id,
          user_profile: this.props.profile,
          winery_group: this.winery_group,
          winery_city: this.winery_city,
          winery_name: this.winery_name,
          tasting_notes: this.state.tasting_notes
        };
        if (this.state.action === "Add") {
          data.created_at = fireStore.FieldValue.serverTimestamp();
          data.created_user = this.props.user.uid;
        } else {
          //          data.created_at = this.state.flight.created_at;
          //        data.created_user = this.state.flight.created_user;
        }

        if (this.state.confirmed) {
          data.confirmed = this.state.confirmed;
        }

        if (this.state.rejected) {
          data.rejected = this.state.rejected;
        }

        this.setState({ busy: true }, () => {
          let theRef;
          if (this.state.action === "Add") {
            theRef = fireStore()
              .collection("submissions")
              .add(data);
          } else {
            theRef = fireStore()
              .collection("submissions")
              .doc(this.state.question_id)
              .set(data); //edit
          }
          theRef
            .then(r => {
              this.setState(
                {
                  busy: false,
                  disabled: true,
                  error: false,
                  setEdit: false
                },
                () => {
                  this.getData().then(() => {
                    if (this.state.submission.confirmed || this.state.submission.rejected && !this.state.isPresident) {
                      if(redirect) {
                        this.props.history.push("/");
                      }
                    }
                  });
                }
              );
            })
            .catch(error => {
              // An error happened.
              this.setState({
                error: error.message,
                busy: false,
                disabled: true,
                setEdit: false
              });
            });
        });
        if (this.refs.form) {
          this.refs.form.resetValidations();
        }
      } else {
        this.setState({
          error: "A submission with already exists.",
          busy: false,
          disabled: true,
          setEdit: false
        });
        if (this.refs.form) {
          this.refs.form.resetValidations();
        }
      }
    });
  }

  confirm() {
    this.setState({ showSuccess: true, dialogAction: '' }, () => {});
   
  }

  confirmConfirmation() {
    this.setState({ confirmed: true }, () => {
      this.handleSubmit(false);
    });
   
  }

  confirmRejection() {
    let fBpath = this.props.match.params.table_id+'/'+this.props.match.params.flight_id+'/'+this.props.match.params.wine_code;
    fireBase().ref(fBpath).set({
      ready: this.state.tablePresidentMsgModal,
      rejected: true
    }).then(() => {
      this.setState({ rejected: true }, () => {
        this.handleSubmit(false);
      });
    })

    
  }


  gotoRoute(route) {
    this.props.history.push(route);
  }

  dialogContent() {
    let dialogContent = (<span> <DialogTitle>Confirm Your Vote?</DialogTitle>
              <DialogContent>
                Are you sure you want to confirm your vote? Once confirmed you <strong>WILL NOT</strong> be able to edit your vote.
                Please make sure you review your answers before continuing.
              </DialogContent>
              <DialogActions className="logout-actions">
                <Button variant="outlined" onClick={this.handleClose}>
                  Back to Review
                </Button>
                <Button variant="outlined" color="primary" onClick={this.confirmConfirmation}>
                  Yes, Confirm Vote
                </Button>
              </DialogActions></span>);
    if(this.state.dialogAction === 'reject') {
      dialogContent = (<span>
              <DialogTitle>Please Confirm</DialogTitle>
              <DialogContent>
                Are you sure you want to mark your submission as 'REJECTED'.<br/>This cannot be undone.
              </DialogContent>
              <DialogActions className="logout-actions">
                <Button variant="outlined" onClick={this.handleClose}>
                  Cancel
                </Button>
                <Button variant="outlined" color="secondary" onClick={this.confirmRejection}>
                  Mark as Rejected
                </Button>
              </DialogActions></span>);
    }
    return dialogContent;
  }

  renderForm() {
    const { classes } = this.props;
    let error = null;
    if (this.state.error) {
      error = <div className="error">{this.state.error}</div>;
    }

    let question_groups = null;
    if (this.state.questions) {
      question_groups = map(this.state.questions, (item, key) => {
        let qs = map(item, (i, k) => {
          let options = map(i.options, (option, optionkey) => {
            let className = "";
            if (option.value === i.value) {
              className = "active";
            }
            return (
              <FormControlLabel
                className={className}
                key={optionkey}
                value={option.value}
                control={<StyledRadio />}
                label={option.label}
              />
            );
          });
          return (
            <div className="questionItem" key={k}>
              <FormControl component="fieldset">
                <FormLabel component="legend">{i.name}</FormLabel>
                <RadioGroup
                  aria-label={i.id}
                  name={i.id}
                  onChange={event => {
                    this.handleChange(event, i.group, i.name);
                  }}
                >
                  {options}
                </RadioGroup>
              </FormControl>
            </div>
          );
        });
        return (
          <span key={key}>
            <h2>{key}</h2>
            {qs}
          </span>
        );
      });
    }
    let rejectBtn = null;
    if(this.state.isPresident) {
      rejectBtn = (<Button
                      className="reject-btn"
                      onClick={this.reject}
                      color="secondary"
                      variant="contained"
                      type="button"
                    >
                      Reject
                    </Button>);
    }

    return (
      <div className="logged-in-with-header tasting-edit">
        <div className="login-form dashboard">
          <div className="admin tables questions">
            <Header
              user={this.props.user}
              profile={this.props.profile}
              developer={this.props.developer}
              administrator={this.props.administrator}
            />
            <div className="page-content">
              <Link className="back-link" to="/">
                <i className="fas fa-chevron-left"></i> Back
              </Link>
              
              {this.renderPageH1()}
              <div className="form">
                {error}
                <ValidatorForm
                  ref="form"
                  onSubmit={this.handleSubmit}
                  onError={errors => console.log("Error submitting form.")}
                >
                  <div className="form-row">{question_groups}</div>

                  <div className="form-row tasting-notes">
                    <h2>Tasting Notes</h2>
                    <TextareaAutosize aria-label="Tasting Notes" onChange={this.handleTextAreaChange} value={this.state.tasting_notes} rowsMin={3} placeholder="Maximum 500 characters" />
                  </div>

                  <div className="form-btns">
                    <Grid container spacing={3}>
                      <Grid item xs={6}></Grid>

                      <Grid item xs={2}>
                        {rejectBtn}
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          className="cancel-btn"
                          onClick={() => {
                            this.gotoRoute("/");
                          }}
                          variant="contained"
                          type="button"
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          className="login-btn"
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={this.state.disabled}
                        >
                          Save And Review
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </ValidatorForm>
              </div>
            </div>
            <Backdrop className={classes.backdrop} open={this.state.busy}>
              <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog
              onClose={this.handleClose}
              disableBackdropClick
              aria-labelledby="simple-dialog-title"
              open={this.state.showSuccess}
            >
              {this.dialogContent()}
              
            </Dialog>
               

          </div>
        </div>
      </div>
    );
  }
  setEdit() {
    this.setState({ submitted: false, disabled: false });
  }

  renderPageH1() {
    return (<h1>{this.state.group_name} #{this.state.tasting_order}</h1>);
  }

  reject() {
    this.setState({ showSuccess: true, dialogAction: 'reject' }, () => { });
  }

  renderRejectedOverlay() {
    let rejectedOverLay = null;
    const { classes } = this.props;
    if(this.state.submission.rejected) {
      rejectedOverLay = (<Dialog className={classes.backdrop} open={true}>
           <div className="rejected-message">
              <div className="rejection-message"> This submission has been marked as rejected.</div>
              <Button
                  onClick={() => {
                    this.gotoRoute("/");
                  }}
                  variant="outlined"
                  type="button"
                  color="secondary"
                >
                  Ok
               </Button>
           </div>
        </Dialog>);
    }
    return rejectedOverLay
  }

  renderPresidentModalActions() {
    //setTastingToReady
    //       <Button onClick={()=> { this.setTastingToReady(false);}}>close</Button>
 
    const { classes } = this.props;
    if(this.state.isPresident && !this.state.submission.rejected) {
      if(this.state.tablePresidentMsgModal) { 
        return (<div><Dialog className={classes.backdrop} open={true}>
             <DialogContent>
                <div>You are the Panel Chair, click Begin below to start this tasting.</div>
             </DialogContent>
             <DialogActions className="center">
            
               <Button color="primary" onClick={()=> { this.setTastingToReady(true);}}>BEGIN THIS TASTING</Button>
             </DialogActions>
          </Dialog>
          
        </div>
        );
      }
    } else {
      if(!this.state.submission.confirmed && !this.state.submission.rejected) {
        return (<Dialog className={classes.backdrop} open={this.state.tablePresidentMsgModal}>
             <DialogContent>
                <div>Please Wait for your Panel Chair, or click <Link to="/">here</Link> to go back to dashboard.</div>

             </DialogContent>
             <DialogActions  className="center">
             </DialogActions>
          </Dialog>);
      } else {
        return null;
      }
    }
    
  }

  render() {

    /*         console.log('preloadData', preloadData);
         /*      .where("wine_code", "==", this.props.match.params.wine_code)
      .where("table_id", "==", this.props.match.params.table_id)
      .where("flight_id", "==", this.props.match.params.flight_id)*/
  /*      console.log('this', this);
        fireBase().ref(this.props.match.params.table_id+'/'+this.props.match.params.flight_id+'/'+this.props.match.params.wine_code).set({
          ready: true
        });

        var starCountRef = firebase.database().ref('posts/' + postId + '/starCount');
starCountRef.on('value', function(snapshot) {
  updateStarCount(postElement, snapshot.val());
});

*/
    let question_groups = null;

    if (this.state.questions) {
      question_groups = map(this.state.questions, (item, key) => {
        let result = map(item, (q, k) => {
          return (
            <Grid
              justify="center"
              alignItems="center"
              alignContent="center"
              key={q.id}
              container
              spacing={0}
              className="result"
            >
              <Grid className="value-header" item xs={11}>
                <div className="wrapper">{q.name}</div>
              </Grid>
              <Grid className="value-score" item xs={1}>
                <div className="wrapper">{q.value}</div>
              </Grid>
            </Grid>
          );
        });

        return (
          <div className="result-group" key={key}>
            <h2 className="result-header">{key}</h2>
            {result}
          </div>
        );
      });
    }

    

    if (this.state.submission.confirmed || this.state.submitted) {
      let sum = reduce(
        this.state.questions,
        (memo, i, k) => {
          let subtotal = reduce(
            i,
            (submemo, subi, subk) => {
              return submemo + parseInt(subi.value);
            },
            0
          );
          return memo + subtotal;
        },
        0
      );

      let confirmBtn = null;
      let btns = null;


      if(this.state.submission.reject) {
        return (<div className="logged-in-with-header tasting-edit">
          <div className="login-form dashboard">
            <div className="admin tables questions">
              <Header
                user={this.props.user}
                profile={this.props.profile}
                developer={this.props.developer}
                administrator={this.props.administrator}
              />

              <div className="page-content">
                <Link className="back-link" to="/">
                  <i className="fas fa-chevron-left"></i> Back
                </Link>
                REJECTED {this.renderPageH1()}
                <div className="results-content">
                  {question_groups}
                  <Grid
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                    container
                    spacing={0}
                    className="result-total"
                  >
                    <Grid className="value-header" item xs={11}>
                      <div className="wrapper">TOTAL POINTS:</div>
                    </Grid>
                    <Grid className="value-score" item xs={1}>
                      <div className="wrapper">{sum}</div>
                    </Grid>
                  </Grid>

                  {btns}
                </div>
              </div>
            </div>
          </div>
        </div>)
      }

      if (!this.state.submission.confirmed) {
        confirmBtn = (
          <Button
            className="login-btn"
            variant="contained"
            color="primary"
            type="button"
            onClick={this.confirm}
          >
            CONFIRM VOTE
          </Button>
        );
        let rejectBtn = null;
        if(this.state.isPresident) {
          rejectBtn = (<Button
                          className="reject-btn"
                          onClick={this.reject}
                          color="secondary"
                          variant="contained"
                          type="button"
                        >
                          Reject
                        </Button>);
        }
        btns = (
          <div className="form-btns">
            <Grid container spacing={3}>
              <Grid item xs={6}></Grid>
              <Grid item xs={2}>{rejectBtn}</Grid>
              <Grid item xs={2}>
                <Button
                  className="cancel-btn"
                  onClick={this.setEdit}
                  variant="contained"
                  type="button"
                >
                  EDIT ANSWERS
                </Button>
              </Grid>
              <Grid item xs={2}>
                {confirmBtn}
              </Grid>
            </Grid>
          </div>
        );
      } else {
        if(this.state.isPresident) {
          btns = ( <div className="form-btns">
            <Grid container spacing={3}>
              <Grid item xs={6}></Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={3}>
                <Button className="reject-btn"
                          color="secondary"
                          variant="contained"
                          type="button" onClick={() => {
                            this.gotoRoute("/");
                          }}>Close this Tasting</Button>
              </Grid>
            </Grid>
          </div>)
        }
      }

      return (
        <div className="logged-in-with-header tasting-edit">
          <div className="login-form dashboard">
            <div className="admin tables questions">
              <Header
                user={this.props.user}
                profile={this.props.profile}
                developer={this.props.developer}
                administrator={this.props.administrator}
              />

              <div className="page-content">
                <Link className="back-link" to="/">
                  <i className="fas fa-chevron-left"></i> Back
                </Link>
                {this.renderPageH1()}
                <div className="results-content">
                  {question_groups}

                  <div className="result-group tasting-notes">
                    <h2 className="result-header">Tasting Notes</h2>
                    <div className="tasting-notes-value">{this.state.submission.tasting_notes}</div>
                  </div>

                  <Grid
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                    container
                    spacing={0}
                    className="result-total"
                  >
                    <Grid className="value-header" item xs={11}>
                      <div className="wrapper">TOTAL POINTS:</div>
                    </Grid>
                    <Grid className="value-score" item xs={1}>
                      <div className="wrapper">{sum}</div>
                    </Grid>
                  </Grid>

                  {btns}
                </div>
              </div>
            </div>
            <Dialog
              onClose={this.handleClose}
              disableBackdropClick
              aria-labelledby="simple-dialog-title"
              open={this.state.showSuccess}
            >
              {this.dialogContent()}
              
            </Dialog>
          </div>
          {this.renderRejectedOverlay()}
          {this.renderPresidentModalActions()}
        </div>
      );
    } else {
      return (<div>{this.renderForm()}

          {this.renderPresidentModalActions()}
          {this.renderRejectedOverlay()}
          </div>);
    }
  }
}

export default withStyles(styles)(TastingEdit);