import React, { Component } from "react";
import { fireStore } from "../constants";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import Header from "../components/header";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Backdrop from "@material-ui/core/Backdrop";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import omit from "lodash/omit";
import isEqual from "lodash/isEqual";
import map from "lodash/map";
import filter from "lodash/filter";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

const styles = theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
});

class QuestionsEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: false,
      profile: false,
      action: "",
      busy: true,
      disabled: true,
      showSuccess: false,
      error: false,
      openDelete: false,
      profiles: [],
      question: {
        name: "",
        lc_name: "",
        group: "",
        options: [],
        order: ""
      }
    };
    this.defaultState = {
      user: false,
      profile: false,
      action: "",
      busy: true,
      disabled: true,
      showSuccess: false,
      error: false,
      openDelete: false,
      profiles: [],
      question: {
        name: "",
        lc_name: "",
        group: "",
        options: [],
        order: ""
      }
    };
    this.collectionName = "questions";

    this.timestamp = new Date().getTime(); //used for name
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.checkForUnique = this.checkForUnique.bind(this);
    this.openDeleteDialog = this.openDeleteDialog.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.handleCloseDelete = this.handleCloseDelete.bind(this);
    this.gotoRoute = this.gotoRoute.bind(this);
    this.addOption = this.addOption.bind(this);
    this.removeOption = this.removeOption.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.validateForm = this.validateForm.bind(this);

    this.collectionsRef = fireStore().collection(this.collectionName);
  }
  componentWillUnmount() {}
  componentDidMount() {
    let action = "";
    if (this.props.match.params.id === "add") {
      action = "Add";
    } else {
      action = "Edit";
    }
    if (action === "Edit") {
      this.collectionsRef
        .doc(this.props.match.params.id)
        .get()
        .then(docSnapshot => {
          if (docSnapshot.exists) {
            let data = docSnapshot.data();
            data.id = docSnapshot.id;

            let newObject = Object.assign({}, this.defaultState.question, data);
            newObject.options = map(data.options, (item, key) => {
              return Object.assign({}, item);
            });
            this.defaultState.question = newObject;

            let existing = Object.assign({}, this.state.question, data);
            this.setState(
              {
                question: existing,
                action: action,
                busy: false
              },
              () => {}
            );
          } else {
            this.setState(
              {
                action: "Add",
                busy: false
              },
              () => {}
            );
          }
        })
        .catch(err => {
          console.log("Error getting documents", err);
        });
    } else {
      this.setState(
        {
          action: "Add",
          busy: false
        },
        () => {}
      );
    }
  }
  handleClose() {
    this.setState({ showSuccess: false, openDelete: false }, () => {
      this.props.history.push("/questions");
    });
  }
  handleCloseDelete() {
    this.setState({ openDelete: false }, () => {});
  }

  validateForm() {
    if (this.refs.form) {
      this.refs.form.isFormValid().then(r => {
        console.log("r", r);
        if (r) {
          let omitKeys = [];
          if (
            isEqual(
              omit(this.state.question, omitKeys),
              omit(this.defaultState.question, omitKeys)
            )
          ) {
            //validate options
            let omitKeysOptions = [];
            console.log("r", r);
            if (
              isEqual(
                omit(this.state.question.options, omitKeysOptions),
                omit(this.defaultState.question.options, omitKeysOptions)
              )
            ) {
              this.setState({ disabled: true });
            } else {
              this.setState({ disabled: false });
            }
          } else {
            this.setState({ disabled: false });
          }
        } else {
          this.setState({ disabled: true });
        }
      });
    } else {
      return true; //disable
    }
  }

  handleChange(event) {
    let newState = {};
    let formName = event.target.name.replace(this.timestamp, "");

    newState[formName] = event.target.value;
    let currentState = Object.assign({}, this.state.question, newState);
    this.setState({ question: currentState }, () => {
      this.setState({ disabled: this.validateForm() });
    });
  }

  handleOptionChange(event, key, uid) {
    let question = this.state.question;
    let options = [...question.options];

    question.options = options;

    question.options[key][uid] = event.target.value;

    this.setState({ question: question }, () => {
      this.setState({ disabled: this.validateForm() });
    });
  }

  addOption() {
    let question = this.state.question;
    let options = [...question.options];
    options.push({
      value: "",
      label: "",
      order: this.state.question.options.length + 1
    });
    question.options = options;
    this.setState({ question: question }, () => {
      this.setState({ disabled: this.validateForm() });
    });
  }
  removeOption(index) {
    let question = this.state.question;
    let options = map(question.options, (item, key) => {
      return Object.assign({}, item);
    });
    if (options.length > 0) {
      options.splice(index, 1);
      question.options = options;

      this.setState({ question: question }, () => {
        this.setState({ disabled: this.validateForm() });
      });
    }
  }

  checkForUnique() {
    let itemRef = fireStore().collection(this.collectionName);
    if (
      this.state.question.name.toLowerCase() !==
      this.defaultState.question.name.toLowerCase()
    ) {
      return itemRef
        .where("lc_name", "==", this.state.question.name.toLowerCase())
        .where("group", "==", this.state.question.group)
        .get()
        .then(snapshot => {
          if (snapshot.empty) {
            return true;
          }
        })
        .catch(err => {
          console.log("Error getting documents", err);
        });
    } else {
      return Promise.resolve(true);
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    this.checkForUnique().then(r => {
      if (r) {
        let data = {
          name: this.state.question.name,
          lc_name: this.state.question.name.toLowerCase(),
          updated_at: fireStore.FieldValue.serverTimestamp(),
          group: this.state.question.group,
          options: this.state.question.options,
          order: this.state.question.order,
          order_int: parseInt(this.state.question.order),
          last_updated_by: this.props.user.uid
        };
        //clean out empty wines
        data.options = filter(data.options, (item, key) => {
          return item.label !== "" && item.value !== "";
        });
        if (this.state.action === "Add") {
          data.created_at = fireStore.FieldValue.serverTimestamp();
          data.created_user = this.props.user.uid;
        }
        this.setState({ busy: true }, () => {
          let theRef;
          if (this.state.action === "Add") {
            theRef = fireStore()
              .collection(this.collectionName)
              .add(data);
          } else {
            theRef = fireStore()
              .collection(this.collectionName)
              .doc(this.state.question.id)
              .set(data); //edit
          }
          theRef
            .then(r => {
              this.setState({
                busy: false,
                disabled: true,
                error: false,
                showSuccess: true
              });
            })
            .catch(error => {
              // An error happened.
              this.setState({
                error: error.message,
                busy: false,
                disabled: true,
                showSuccess: false
              });
            });
        });
        this.refs.form.resetValidations();
      } else {
        this.setState({
          error:
            "A question with that name already exists, please use a unique question name",
          busy: false,
          disabled: true,
          showSuccess: false
        });
        this.refs.form.resetValidations();
      }
    });
  }
  openDeleteDialog() {
    this.setState({ openDelete: true });
  }
  deleteItem() {
    return fireStore()
      .collection(this.collectionName)
      .doc(this.state.question.id)
      .delete()
      .then(r => {
        this.props.history.push("/questions");
      });
  }
  gotoRoute(route) {
    this.props.history.push(route);
  }

  render() {
    const { classes } = this.props;
    let error = null;
    if (this.state.error) {
      error = <div className="error">{this.state.error}</div>;
    }
    let deletebtn = null;
    if (this.state.action === "Edit") {
      deletebtn = (
        <Button
          onClick={this.openDeleteDialog}
          className="delete-btn"
          variant="contained"
          color="primary"
          type="button"
        >
          Delete question
        </Button>
      );
    }

    var questionItems = this.state.question.options.map((item, key) => {
      return (
        <li key={key} className="tasting-item">
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={1}>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  id={"order_" + key}
                  value={item.order}
                  onChange={event => {
                    this.handleOptionChange(event, key, "order");
                  }}
                  type="number"
                />
              </FormControl>
            </Grid>
            <Grid item xs={5}>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  id={"value_" + key}
                  value={item.value}
                  onChange={event => {
                    this.handleOptionChange(event, key, "value");
                  }}
                  type="text"
                />
              </FormControl>
            </Grid>
            <Grid item xs={5}>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  id={"label_" + key}
                  value={item.label}
                  onChange={event => {
                    this.handleOptionChange(event, key, "label");
                  }}
                  type="text"
                />
              </FormControl>
            </Grid>
            <Grid item xs={1}>
              <Fab
                color="primary"
                size="small"
                aria-label="add"
                onClick={() => {
                  this.removeOption(key);
                }}
                className="remove-tasting-btn"
              >
                -
              </Fab>
            </Grid>
          </Grid>
        </li>
      );
    });
    let tasting_content;
    if (this.state.question.options.length) {
      tasting_content = (
        <div>
          <Grid container className="grid-header" spacing={3}>
            <Grid item xs={1}>
              Order
            </Grid>
            <Grid item xs={5}>
              Value
            </Grid>
            <Grid item xs={5}>
              Label
            </Grid>

            <Grid item xs={1}></Grid>
          </Grid>
          <ul className="wines-list">{questionItems}</ul>
        </div>
      );
    } else {
      tasting_content = <p>There are no options added.</p>;
    }
    let saveText = this.state.action;
    if (this.state.action === "Edit") {
      saveText = "Save";
    }

    return (
      <div className="logged-in-with-header">
        <div className="login-form dashboard">
          <div className="admin questions">
            <Header user={this.props.user} profile={this.props.profile} />
            <div className="page-content">
              <Link className="back-link" to="/questions">
                <i className="fas fa-chevron-left"></i> Back
              </Link>
              <div className="actions">{deletebtn}</div>
              <h1>{this.state.action} question</h1>
              <div className="form">
                {error}
                <ValidatorForm
                  ref="form"
                  onSubmit={this.handleSubmit}
                  onError={errors => console.log(errors)}
                >
                  <div className="form-row">
                    <TextValidator
                      fullWidth
                      name={"name" + this.timestamp}
                      value={this.state.question.name}
                      onChange={this.handleChange}
                      label="Enter a Question"
                      required
                      validators={["required", "minStringLength:1"]}
                      errorMessages={[
                        "This field is required",
                        "This field is required"
                      ]}
                    />
                  </div>

                  <div className="form-row">
                    <TextValidator
                      fullWidth
                      name={"group" + this.timestamp}
                      value={this.state.question.group}
                      onChange={this.handleChange}
                      label="Enter a Group"
                      required
                      validators={["required", "minStringLength:1"]}
                      errorMessages={[
                        "This field is required",
                        "This field is required"
                      ]}
                    />
                  </div>

                  <div className="form-row">
                    <TextValidator
                      fullWidth
                      name={"order" + this.timestamp}
                      value={this.state.question.order}
                      onChange={this.handleChange}
                      type="number"
                      label="Enter a Order"
                      required
                      validators={["required", "minStringLength:1"]}
                      errorMessages={[
                        "This field is required",
                        "This field is required"
                      ]}
                    />
                  </div>

                  <div className="wines">
                    <h2>options</h2>
                    {tasting_content}
                    <Button
                      className="add-tasting-btn"
                      onClick={this.addOption}
                      variant="contained"
                      type="button"
                    >
                      + Add
                    </Button>
                  </div>

                  <div className="form-btns">
                    <Grid container spacing={3}>
                      <Grid item xs={8}></Grid>
                      <Grid item xs={2}>
                        <Button
                          className="cancel-btn"
                          onClick={() => {
                            this.gotoRoute("/questions");
                          }}
                          variant="contained"
                          type="button"
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          className="login-btn"
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={this.state.disabled}
                        >
                          {saveText} question
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </ValidatorForm>
              </div>
            </div>
            <Backdrop className={classes.backdrop} open={this.state.busy}>
              <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog
              onClose={this.handleClose}
              disableBackdropClick
              aria-labelledby="simple-dialog-title"
              open={this.state.showSuccess}
            >
              <DialogContent>
                Success, your question{" "}
                <strong>'{this.state.question.name}'</strong> has been updated.
              </DialogContent>
              <DialogActions className="logout-actions">
                <Button autoFocus onClick={this.handleClose}>
                  Ok
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              onClose={this.handleCloseDelete}
              aria-labelledby="simple-dialog-title"
              open={this.state.openDelete}
            >
              <DialogTitle>Delete</DialogTitle>
              <DialogContent>
                Are you sure you want to delete{" "}
                <strong>'{this.state.question.name}'</strong>. This cannot be
                undone.
              </DialogContent>
              <DialogActions className="logout-actions">
                <Button autoFocus onClick={this.handleCloseDelete}>
                  Cancel
                </Button>
                <Button autoFocus onClick={this.deleteItem}>
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(QuestionsEdit);