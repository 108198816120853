import React, { Component } from "react";
import { fireStore } from "../constants";
import Fab from "@material-ui/core/Fab";
import Header from "../components/header";
import AddIcon from "@material-ui/icons/Add";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
const styles = theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
});
class Users extends Component {
  constructor(props) {
    super(props);
    this.collectionName = "profiles";
    this.state = {
      user: false,
      profiles: [],
      busy: true
    };

    this.gotoRoute = this.gotoRoute.bind(this);
  }
  componentWillUnmount() {}
  componentDidMount() {
  
    let profilesRef = fireStore().collection(this.collectionName);
    profilesRef
      .orderBy("lastName", "asc")
      .get()
      .then(snapshot => {
        let userData = [];
        snapshot.forEach(doc => {
          let data = doc.data();
          data.id = doc.id;
          userData.push(data);
        });
        this.setState({ profiles: userData, busy: false });
      })
      .catch(err => {
        console.log("Error getting documents", err);
      });
  }

  gotoRoute(route) {
    this.props.history.push(route);
  }

  render() {
    const { classes } = this.props;
    var profileItems = this.state.profiles.map(function(item) {
      return (
        <li key={item.id}>
          <Link to={"/users/" + item.id}>
            {item.lastName}, {item.firstName} - {item.email} | {item.id} 
          </Link>
          <Button>Masquerade</Button>
        </li>
      );
    });
    let content;
    if (this.state.profiles.length) {
      content = (
        <div>
          <h3>{this.state.profiles.length} Total Users</h3>
          <ul>{profileItems}</ul>
        </div>
      );
    } else {
      content = <p>There are no users added.</p>;
    }

    return (
      <div className="logged-in-with-header">
        <div className="login-form dashboard">
          <div className="admin tables">
            <Header
              user={this.props.user}
              profile={this.props.profile}
              developer={this.props.developer}
              administrator={this.props.administrator}
            />
            <div className="page-content">
              <div className="actions">
                <Fab
                  onClick={() => {
                    this.gotoRoute("/tables/add");
                  }}
                  size="small"
                  color="primary"
                  aria-label="add"
                >
                  <AddIcon />
                </Fab>
              </div>
              <h1>Manage Users</h1>
              <div className="table-content">{content}</div>
            </div>
            <Backdrop className={classes.backdrop} open={this.state.busy}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Users);